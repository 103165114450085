import request from '@/utils/requestTechsso.js'

export function GetDefaultValuePublic(data) {
  return request({
    url: 'Default/GetDefaultValuePublic',
    method: 'post',
    data: data
  })
}

export function DTPGetValue(data) {
  return request({
    url: 'Default/DTPGetValue',
    method: 'post',
    data: data
  })
}