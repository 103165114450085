<template>
  <div>
    <div class="container-image" @click="btSeeInfoProduct">
      <img class="frame-image" :src="productInfo.ImgLst[0].LinkImage" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productInfo: {},
  },
  methods: {
    btSeeInfoProduct() {
      this.$router.push({
        path: "/thong-tin-san-pham/" + this.productInfo.ProductID,
      });
    },
  },
};
</script>

<style scoped>
.container-image {
  margin-top: 16px;
  margin-left: 12px;
  background: white;
  height: 216px;
  width: 90%;
  position: relative;
  border-radius: 12px;
  /* text-align: center; */
}
.frame-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* width: auto; */
  width: 150px;
  max-height: 216px;
  /* border: 1px solid #000; */
  /* background: rgb(189, 62, 62); */
  border-radius: 8px;
}
</style>
