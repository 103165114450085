<template>
  <div class="container-userCare">
    <div class="userCare-mobile-frame">
      <div class="userCare-title">
        <h2 class="title">TIN TỨC</h2>
        <div class="linetitle"></div>
      </div>

      <div class="userCare-mobile-frame-cellLst" v-loading="loading">
        <div class="lstCareCell">
          <userCareCell
            v-for="(userCare, index) in userCareLst"
            :key="index"
            :userCareInfo="userCare"
          ></userCareCell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userCareCell from "../components/user-care-cell.vue";
import { TGetPostLstPublic } from "@/api/postApi.js";

export default {
  data() {
    return {
      userCareLst: [],
      userCareLstAll: [],
      loading: false,
    };
  },
  components: {
    userCareCell,
  },
  methods: {
    getPostLstPublic() {
      TGetPostLstPublic({
        PageNumber: 1,
        RowspPage: 8,
        Search: "",
        Type: "",
      }).then((res) => {
        if (res.RespCode == 0) {
          this.userCareLst = res.Data;
        }
      });
    },
  },
  created() {
    this.getPostLstPublic();
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
  font-size: 20px;
}
.linetitle {
  height: 6px;
  width: 80px;
  background: #26a9e1;
  border-radius: 8px;
  margin: auto;
}
.subtitle {
  font-size: 14px;
  margin-top: 12px;
  text-align: center;
  margin-bottom: 16px;
}
.lstCareCell {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.userCare-frame {
  /* margin-top: 10px; */
  /* background-color: #fcf3e8; */
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.userCare-header {
  background-color: #26a9e1;
  height: 44px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.userCare-mobile-frame-cellLst {
  margin-top: 15px;
  margin-bottom: -5px;
  min-height: 200px;
}
.userCare-title {
  /* display: flex; */
  padding-top: 12px;
  /* padding-left: 20px; */
}
.userCare-bt-seeall {
  background: none;
  border: none;
  color: #fff;
  margin-right: 20px;
}

.userCare-mobile-header {
  height: 40px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.userCare-mobile-frame {
  height: auto;
  /* background: #fcf3e8; */
}

@media (max-width: 678px) {
  .lstCareCell {
    /* display: grid; */
    grid-template-columns: 100%;
  }
}
.container-userCare {
  max-width: 1240px !important;
  margin: auto !important;
}
</style>
