<template>
  <div class="container" style="line-height: 25px">
    <div class="security-container">
      <h1 class="">CHÍNH SÁCH BẢO MẬT THÔNG TIN KHÁCH HÀNG</h1>
      <p class="mt-20 ml-50 mr-50" style="text-align: center">
        Bạn hoàn toàn có thể yên tâm về các thông tin của mình trên medisungroup.vn
        (Medisun) bởi chúng tôi ưu tiên hàng đầu trong việc bán hàng và cung cấp
        dịch vụ IT tại website. Bạn vui lòng đọc bản “Chính sách bảo mật” dưới
        đây để hiểu hơn những cam kết mà chúng tôi thực hiện, nhằm tôn trọng và
        bảo vệ quyền lợi của người truy cập:
      </p>
      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">1. Mục đích thu thập thông tin</p>
          <div class="security-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20">
              Medisun chỉ thu thập thông tin liên lạc cần thiết để thực
            hiện giao dịch giữa website và khách hàng mà không lấy thêm thông
            tin gì khác. Chúng tôi thu thập và sử dụng thông tin cá nhân của
            khách hàng với mục đích phù hợp và hoàn toàn tuân thủ quy định pháp
            luật. Chúng tôi cam kết không chia sẻ thông tin cá nhân của khách
            hàng với bất kỳ một bên thứ ba nào khác vì mục đích lợi nhuận.
            <br />
            <br />
            Khi cần thiết, chúng tôi có thể sử dụng những thông tin này để liên
            hệ trực tiếp với khách hàng dưới các hình thức như: Đơn đặt hàng,
            gửi thư định kỳ thông tin về các chương trình khuyến mãi, dịch vụ
            mới, thông tin sản phẩm,...
          </p>
        </div>
      </div>
      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">
            2. Những thông tin chúng tôi thu thập bao gồm:
          </p>
          <div class="security-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20">
            - Họ và tên <br />
            - Số điện thoại <br />
            - Giới tính <br />
            - Địa chỉ giao hàng <br />
            - Thông tin khác (nếu có).
          </p>
        </div>
      </div>
      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">3. Phạm vi thu thập thông tin</p>
          <div class="security-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20">
            Thông tin của khách hàng sẽ chỉ được sử dụng trong nội bộ hệ thống
              Medisun với các mục đích sau đây: <br />
            - Xử lý đơn hàng: gọi điện/tin nhắn xác nhận việc đặt hàng, thông
            báo về trạng thái đơn hàng & thời gian giao hàng, xác nhận việc huỷ
            đơn hàng (nếu có). <br />
            - Gửi thư ngỏ/ thư cảm ơn, giới thiệu sản phẩm mới, dịch vụ mới hoặc
            các chương trình khuyến mãi của  . <br />
            - Gửi thông tin về bảo hành sản phẩm. <br />
            - Giải quyết khiếu nại của khách hàng. <br />
            - Các khảo sát để chăm sóc khách hàng tốt hơn. <br />
            - Xác nhận các thông tin về kỹ thuật & bảo mật thông tin khách hàng.
            <br />
            - Các trường hợp có sự yêu cầu của cơ quan nhà nước có thẩm quyền,
            theo đúng quy định của pháp luật. <br />
          </p>
        </div>
      </div>
      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">4. Thời gian lưu trữ thông tin</p>
          <div class="security-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20">
            Dữ liệu cá nhân của khách hàng sẽ được lưu trữ cho đến khi có yêu
            cầu hủy bỏ. Còn lại trong mọi trường hợp thông tin cá nhân khách
            hàng sẽ được bảo mật trên máy chủ của website.
          </p>
        </div>
      </div>
      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">
            5. Những người hoặc tổ chức có thể được tiếp cận với thông tin của
            khách hàng
          </p>
          <div class="security-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20">
            Trong trường hợp cần thiết, các cơ quan/ tổ chức/ cá nhân sau có
            quyền được tiếp cận và thu thập thông tin cá nhân mà khách hàng cung
            cấp, bao gồm: <br />
            Ban quản trị, nhân viên Công ty Cổ phần Medisun. <br />
            Bên thứ ba có dịch vụ tích hợp với website Medisun.vn. <br />
            Đơn vị vận chuyển liên kết với Công ty để giao hàng cho khách hàng.
            <br />
            Theo yêu cầu của cơ quan nhà nước có thẩm quyền.
          </p>
        </div>
      </div>
      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">
            6. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân
          </p>
          <div class="security-triangle"></div>
        </div>

        <div>
          <p class="mt-20 ml-40 mr-20">
            CÔNG TY CỔ PHẦN MEDISUN <br />
            Địa chỉ: Cụm Công nghiệp Hà Bình Phương, Xã Văn Bình, Huyện Thường
            Tín, Hà Nội. <br />
            Giấy phép kinh doanh số 0109313177 do Sở Kế Hoạch Và Đầu Tư Thành
            Phố Hà Nội cấp ngày 18/08/2020. <br />
            Hotline: 0866.681.899 <br />
            Email: medisungroup.vn@gmail.com
          </p>
        </div>
      </div>

      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">
            7. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân của mình
          </p>
          <div class="security-triangle"></div>
        </div>

        <div>
          <p class="mt-20 ml-40 mr-20">
            Bất cứ thời điểm nào khách hàng cũng có thể truy cập và chỉnh sửa
            những thông tin cá nhân của mình theo các bước hướng dẫn thích hợp
            mà chúng tôi cung cấp.
          </p>
        </div>
      </div>

         <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px" >
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">
        8.	Cam kết bảo vệ thông tin cá nhân khách hàng
          </p>
          <div class="security-triangle"></div>
        </div>

        <div>
          <p class="mt-20 ml-40 mr-20">
         Chúng tôi đảm bảo rằng thông tin cá nhân của khách hàng sẽ được lưu giữ an toàn. Ngoại trừ các trường hợp về việc sử dụng thông tin cá nhân như đã nêu trong chính sách này, chúng tôi cam kết sẽ không tiết lộ thông tin cá nhân khách hàng ra ngoài vì mục đích thương mại. Chúng tôi có thể tiết lộ hoặc cung cấp thông tin cá nhân của khách hàng trong các trường hợp thật sự cần thiết như sau: <br>
-	Khi có yêu cầu của cơ quan pháp luật. <br>
-	Trong trường hợp mà điều đó giúp chúng tôi bảo vệ quyền lợi chính đáng của mình trước pháp luật. <br>
-	Tình huống khẩn cấp và cần thiết để bảo đảm quyền an toàn cá nhân của các thành viên khác.

          </p>
        </div>
      </div>


      <div>
        <div class="security-frame-name mt-30" style="width: fit-content; padding-right: 40px">
          <i class="fa-solid fa-shield-halved"></i>
          <div class="security-line"></div>
          <div class="security-line"></div>
          <p class="security-title">
            9. CƠ CHẾ TIẾP NHẬN VÀ GIẢI QUYẾT KHIẾU NẠI LIÊN QUAN ĐẾN VIỆC THÔNG
            TIN CỦA KHÁCH HÀNG
          </p>
          <div class="security-triangle"></div>
        </div>

        <div>
          <p class="mt-20 ml-40 mr-20">
            Khi phát hiện thông tin cá nhân của mình bị sử dụng sai mục đích
            hoặc phạm vi, khách hàng gửi email khiếu nại đến email
            medisungroup.vn@gmail.com hoặc gọi điện thoại tới số 0866.681.899 để
            khiếu nại và cung cấp chứng cứ liên quan tới vụ việc cho Ban quản
            trị. Ban quản trị cam kết sẽ phản hồi ngay lập tức hoặc muộn nhất là
            trong vòng 24 giờ làm việc kể từ thời điểm nhận được khiếu nại.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  text-align: center;
  color: #e35a14;
}
p {
  font-size: 16px;
  /* line-height: 1.4; */
}
.security-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 40px;
  margin-top: 20px;
  /* text-align: center; */
}
.security-frame-name {
  background: linear-gradient(90deg, #f0bd4a -10.81%, #e68241 118.47%);
  height: 48px;
  width: 50%;
  text-align: left;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  position: relative;
}
.security-frame-name i {
  font-size: 20px;
  color: #fff;
  margin-top: 14px;
  margin-left: 15px;
  margin-right: 8px;
}
.security-line {
  margin-left: 6px;
  width: 3px;
  background-color: #fff;
  height: 48px;
}
.security-title {
  margin-top: 12px;
  margin-left: 16px;
  color: #fff;
  font-size: 20px;
}
.security-triangle {
  position: absolute;
  right: 0px;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 24px solid rgb(255, 255, 255);
}
</style>
