<template>
  <div class="container-chatbox" style="width: 64px; height: 64px">
    <div
      class="chatbox background-gradient"
      v-if="isShowChatBox"
      @click="isShowChatBox = !isShowChatBox"
    >
      <i class="fa-solid fa-comment-dots"></i>
    </div>
    <div class="chatbox2 background-gradient">
      <a href="tel:0355031003">
        <i class="fa-solid fa-phone-volume"></i>
      </a>
    </div>
    <div class="box-message" v-if="!isShowChatBox">
      <div class="top background-gradient">
        <i
          class="fa-solid fa-xmark"
          style="margin-right: 6px"
          @click="isShowChatBox = !isShowChatBox"
        ></i>
      </div>
      <div class="body" v-if="true">
        <div class="logo">
          <img src="@/assets/images/medisun.png" alt="" />
        </div>

        <p class="text">Bắt đầu trò chuyện với chúng tôi</p>
        <div class="zalo" @click="btZalo">
          <img src="@/assets/zalo.png" alt="" />
          <p>Chat Zalo (8h00 - 22h00)</p>
        </div>
        <div class="now-message" @click="btMessage">
          <i class="fa-brands fa-facebook-messenger"></i>
          <p>Trực tuyến (8h00 - 22h00)</p>
        </div>
      </div>
      <div class="message-now"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowChatBox: true,
    };
  },
  methods: {
    btZalo() {
      window.open("https://zalo.me/0869482306");
      // window.open()
    },
    btMessage() {
      window.open("https://www.facebook.com/messages/t/112574138089287");
      // window.open()
    },
  },
};
</script>
<style lang="scss" scoped>
.container-chatbox {
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
  //   height: 60px;
  //   width: 60px;

  .chatbox {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.55);
    border: 2px solid #fff;
    margin-bottom: 8px;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 32px;
    }
  }
  .chatbox2 {
    position: relative;

    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.55);
    border: 2px solid #fff;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 28px;
    }
  }
  .box-message {
    position: absolute;
    top: -400px;
    right: 0;
    height: 400px;
    width: 300px;
    // color: #ccc;
    background: #fff;
    // border: 1px solid #26A9E1;
    border-radius: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.55);
    .top {
      margin-top: -2px;
      margin-left: -1px;
      height: 40px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      text-align: right;
      // padding: 8px;
      i {
        color: #fff;
        text-align: right;
        // margin: 8px;
        line-height: 40px;
        width: 24px;
        padding-right: 8px;
        cursor: pointer;
      }
    }
    .body {
      .logo {
        text-align: center;
        margin-top: 24px;
        img {
          height: 48px;
        }
      }
      .text {
        margin: 24px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #26a9e1;
      }
      .zalo {
        height: 44px;
        background: #004de8;
        margin: 16px;
        border-radius: 8px;
        display: flex;
        img {
          margin-top: 8px;
          margin-left: 24px;
          height: 28px;
          //   line-height: 44px;
        }
        p {
          color: #fff;
          font-size: 14px;
          line-height: 44px;
          margin-left: 8px;
        }
      }
      .now-message {
        height: 44px;
        background: #fff;
        margin: 16px;
        border-radius: 8px;
        display: flex;
        border: 1px solid #26a9e1;
        i {
          margin-top: 8px;
          margin-left: 24px;
          font-size: 28px;
          color: #26a9e1;
        }
        p {
          color: #26a9e1;
          font-size: 14px;
          line-height: 44px;
          margin-left: 8px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
