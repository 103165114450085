<template>
  <div class="container">
    <div class="tagname mt-20">
      <a class="tag" href="">Trang chủ / </a>
      <a class="tag" href="">Hướng dẫn</a>
    </div>
    <div class="genui-container" style="line-height: 25px">
      <img src="@/assets/images/muahohangnhat.png" alt="" />

      <header>
        <h1>Quy trình mua hộ hàng Nhật từ Việt Nam</h1>
        <h2>5 bước đơn giản, dễ dàng</h2>
      </header>
      <main>
        <section id="step1">
          <h3>Bước 1: Chọn sản phẩm</h3>
          <p>
            Truy cập các trang web mua sắm trực tuyến của Nhật Bản, chẳng hạn
            như Amazon Nhật Bản, Rakuten, Yahoo! Auctions, v.v., để tìm kiếm sản
            phẩm muốn mua.
          </p>
          <p>
            Khi đã chọn được sản phẩm, bạn cần kiểm tra thông tin sản phẩm, bao
            gồm giá cả, thông số kỹ thuật, hình ảnh, v.v., và lưu ý đến các điều
            khoản và chính sách của trang web mua sắm, chẳng hạn như phí vận
            chuyển, phí thuế, thời gian giao hàng, v.v.
          </p>
        </section>

        <section id="step2">
          <h3>Bước 2: Liên hệ với dịch vụ mua hộ</h3>
          <p>
            Liên hệ với dịch vụ mua hộ qua điện thoại, email, hoặc website để
            cung cấp thông tin sản phẩm muốn mua, bao gồm link sản phẩm, số
            lượng, màu sắc, kích thước, v.v. Dịch vụ mua hộ sẽ báo giá cho bạn
            trong vòng 24 giờ.
          </p>
        </section>

        <section id="step3">
          <h3>Bước 3: Đặt hàng và thanh toán</h3>
          <p>
            Đồng ý với giá cả và các điều khoản của dịch vụ mua hộ thì tiến hành
            đặt hàng và thanh toán. Bạn có thể thanh toán qua thẻ ngân hàng,
            chuyển khoản, hoặc tiền mặt.
          </p>
        </section>

        <section id="step4">
          <h3>Bước 4: Mua hàng và vận chuyển</h3>
          <p>
            Dịch vụ mua hộ sẽ tiến hành mua hàng và vận chuyển hàng hóa từ Nhật
            Bản về Việt Nam. Thời gian vận chuyển thường từ 3-7 ngày, tùy thuộc
            vào phương thức vận chuyển và địa chỉ giao hàng.
          </p>
        </section>

        <section id="step5">
          <h3>Bước 5: Nhận hàng và kiểm tra</h3>
          <p>
            Khi hàng hóa đến Việt Nam, dịch vụ mua hộ sẽ thông báo cho bạn. Bạn
            cần thanh toán phí vận chuyển nội địa và nhận hàng. Sau khi nhận
            hàng, bạn cần kiểm tra hàng hóa cẩn thận để đảm bảo hàng hóa không
            bị hư hỏng trong quá trình vận chuyển. Nếu có bất kỳ vấn đề gì với
            hàng hóa, bạn cần liên hệ với dịch vụ mua hộ để được hỗ trợ.
          </p>
        </section>
      </main>
    </div>
    <div class="genui-container" style="line-height: 25px">
      <img src="@/assets/images/quy-trinh-mua-hang.png" alt="" />

      <header>
        <h1>Quy trình mua hàng của website MEDISUN</h1>
        <h2>5 bước đơn giản, dễ dàng</h2>
      </header>

      <main>
        <section id="step1">
          <h3>Bước 1: Tìm kiếm sản phẩm</h3>
          <p>
            Bạn có thể tìm kiếm sản phẩm trên website của MEDISUN bằng cách nhập
            từ khóa hoặc sử dụng bộ lọc để tìm kiếm theo danh mục, thương hiệu,
            giá cả, v.v.
          </p>
          <div class="frame-image">
            <img
              src="@/assets/images/search-product.png"
              alt="Tìm kiếm sản phẩm"
            />
          </div>
        </section>

        <section id="step2">
          <h3>Bước 2: Thêm sản phẩm vào giỏ hàng</h3>
          <p>
            Sau khi tìm thấy sản phẩm bạn muốn mua, hãy nhấp vào nút "Thêm vào
            giỏ hàng".
          </p>
          <div class="frame-image">
            <img
              src="@/assets/images/add-to-cart.png"
              alt="Thêm sản phẩm vào giỏ hàng"
            />
          </div>
        </section>

        <section id="step3">
          <h3>Bước 3: Chọn phương thức vận chuyển</h3>
          <p>
            Bạn có thể chọn phương thức vận chuyển phù hợp với nhu cầu của mình.
            MEDISUN cung cấp các phương thức vận chuyển sau:
          </p>
          <ul>
            <li>
              <strong>Vận chuyển nội địa</strong>
              <p>
                MEDISUN cung cấp dịch vụ vận chuyển nội địa miễn phí cho đơn
                hàng từ 500.000 đồng.
              </p>
            </li>
            <li>
              <strong>Vận chuyển quốc tế</strong>
              <p>
                MEDISUN hợp tác với các đơn vị vận chuyển uy tín như DHL, UPS,
                Fedex, v.v. để cung cấp dịch vụ vận chuyển quốc tế nhanh chóng
                và an toàn.
              </p>
            </li>
          </ul>
        </section>

        <section id="step4">
          <h3>Bước 4: Thanh toán</h3>
          <p>
            Bạn có thể thanh toán bằng thẻ ngân hàng, chuyển khoản, hoặc tiền
            mặt.
          </p>
        </section>

        <section id="step5">
          <h3>Bước 5: Nhận hàng</h3>
          <p>
            Sau khi thanh toán thành công, bạn sẽ nhận được hàng trong thời gian
            từ 3-7 ngày, tùy thuộc vào phương thức vận chuyển và địa chỉ nhận
            hàng.
          </p>
          <div class="frame-image">
            <img src="@/assets/images/receive-goods.png" alt="Nhận hàng" />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #26A9E1;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.tagname {
  margin-left: 8px;
}
.container {
  display: block;
}
.genui-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 20px 12px;
  margin: 8px;
  margin-top: 8px;
}
body {
  font-family: sans-serif;
  font-size: 16px;
}

header {
  /* background-color: #000; */
  color: #26A9E1;
  padding-top: 20px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

main {
  margin-top: 20px;
}

section {
  margin-bottom: 20px;
  // position: relative;
  .frame-image {
    display: flex;
    justify-content: center;
    img {
      width: 80%;
      margin: 20px auto;
      text-align: center;
    }
  }
}

p {
  line-height: 1.5;
}

img {
  width: 100%;
}
</style>
