<template>
  <div class="banner">
    <div class="container">
      <div class="banner-frame-image">
        <el-carousel
          height=""
          width="100%"
          style="z-index: -10"
          class="banner-image1"
        >
          <el-carousel-item v-for="(item, index) in listBanner" :key="index">
            <img width="100%" height="100%" :src="item.LinkImage" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="special-product">
        <div class="product">
          <div class="banner-header background-gradient">
            <div class="banner-title">
              <i class="fa-solid fa-cannabis"></i>
              <h4 class="pl-10 color-white">Sản phẩm nổi bật</h4>
            </div>
          </div>
          <div class="banner-container-special" v-loading="loading">
            <VueSlickCarousel
              ref="carousel"
              v-bind="settings"
              class=""
              v-if="speacialProduct.length > 0"
            >
              <SpeacialProduct
                v-for="(productInfo, index) in this.speacialProduct"
                :key="index"
                :productInfo="productInfo"
              ></SpeacialProduct>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="img-container">
          <img
            src="@/assets/images/bannerhome.png"
            alt=""
            class="image-intro"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import SpeacialProduct from "./components/speacial-product.vue";
import { GetProductLstPublic } from "@/api/productApi.js";

import { TGetBannerLstPublic } from "@/api/bannerApi.js";
export default {
  data() {
    return {
      listBanner: [],
      loading: false,
      speacialProduct: [],
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        initialSlide: 4,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: {
    VueSlickCarousel,
    SpeacialProduct,
  },

  methods: {
    getBanner() {
      TGetBannerLstPublic({}).then((res) => {
        if (res.RespCode == 0) {
          this.listBanner = res.Data;
        }
      });
    },

    getProductLst() {
      this.loading = true;
      GetProductLstPublic({
        RowspPage: 10,
        PageNumber: 1,
        Search: "",
        StoreCode: "MD000007",
      }).then((res) => {
        this.loading = false;
        this.speacialProduct = res.Data;
      });
    },
  },
  created() {
    this.getProductLst();
    this.getBanner();
  },
};
</script>
<style lang="scss" scoped>
.banner {
  padding-top: 200px;
}
.container {
  display: block;
  .special-product {
    display: flex;
    margin-bottom: 8px;
    .product {
      width: calc(100% - 392px);
      margin-right: 12px;
      margin-bottom: 4px;
      background: #26a9e128;
      border-radius: 8px;
    }
    .banner-container-special {
      min-height: 255px;
    }
  }
  .image-intro {
    // max-height: 300px;
    height: calc(100% - 4px);
    max-width: 380px;
    border-radius: 12px;
    object-fit: cover;
  }
}

h4 {
  text-align: left;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.75);
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.banner-frame-image {
  margin-top: 12px;
  margin-bottom: 12px;
  // border-radius: 8px;
}

.banner-header {
  height: 44px;
  // border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;

  .banner-title {
    display: flex;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
  }
}
@media screen and (max-width: 1200px) {
  .banner {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 865px) {
  .container {
    .special-product {
      display: flex;
      .product {
        width: calc(100% - 300px);
      }
    }
    .image-intro {
      max-height: 300px;

      border-radius: 12px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 678px) {
  .container {
    .special-product {
      display: block;
      .product {
        width: 100%;
        .banner-header {
          height: 36px;
          .banner-title {
            padding-top: 8px;
            padding-left: 10px;
          }
        }
        .banner-container-special {
          min-height: 200px;
        }
      }
    }
    .img-container {
      display: flex;
      justify-content: center;
      .image-intro {
        max-height: 260px;
        min-width: 375px;
      }
    }
  }
}
</style>
<style>
.banner-container-special .slick-prev {
  margin-left: 24px;
  margin-top: -16px;
  z-index: 1;
}
.banner-container-special .slick-next {
  margin-right: 60px;
  margin-top: -16px;
}
.banner-container-special .slick-prev:before {
  content: url("~@/assets/images/chevronleft.png");
  font-size: 50px;
}
.banner-container-special .slick-next:before {
  content: url("~@/assets/images/chevronright.png");
  font-size: 50px;
}
.banner-frame-image .el-carousel .el-carousel__container {
  height: 300px;
  /* height: 100%; */
  z-index: -10 !important;
}
@media (max-width: 560px) {
  .banner-frame-image .el-carousel .el-carousel__container {
    height: 180px !important;
  }
}
@media (min-width: 560px) {
  .banner-frame-image .el-carousel .el-carousel__container {
    height: 240px !important;
  }
}
@media (min-width: 760px) {
  .banner-frame-image .el-carousel .el-carousel__container {
    height: 320px !important;
  }
}
@media (min-width: 960px) {
  .banner-frame-image .el-carousel .el-carousel__container {
    height: 360px !important;
  }
}
@media (min-width: 1200px) {
  .banner-frame-image .el-carousel .el-carousel__container {
    height: 400px !important;
  }
}
</style>
