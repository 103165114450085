<template>
  <div class="bg-footer">
    <div class="container">
      <el-row :gutter="12">
        <el-col :lg="8" :md="8" :sm="24">
          <div>
            <div class="title">LIÊN HỆ</div>
            <div class="linetitle"></div>
            <div class="name-company">CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN</div>
            <div class="content" :gutter="24">
              <div class="title-contact">
                <strong>Trụ sở chính:</strong> Phan Đình Giót, Phương La Khê,
                Quận Hà Đông, Thành phố Hà Nội
              </div>

              <div class="phone-contact">
                <i class="fa-solid fa-phone-volume"></i>
                Hành chính: <a href="tel:0922401534">0922 401 534</a>
              </div>
              <div class="title-contact">
                <strong>VPGD Hà Nội:</strong> Tòa Metropolitan CT36A, Định Công,
                Hoàng Mai, Hà Nội
              </div>

              <div class="phone-contact">
                <i class="fa-solid fa-phone-volume"></i>
                Hotline 1: <a href="tel:0355031003">0355 031 003</a>
              </div>
              <div class="title-contact">
                <strong>Chi nhánh Hồ Chí Minh:</strong> Đường 3/2 Phường 12 Quận 10 Hồ Chí Minh
              </div>

              <div class="phone-contact">
                <i class="fa-solid fa-phone-volume"></i>
                Hotline 3: <a href="tel:0812327699">0812 327 699</a>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24">
          <div>
            <div class="title">HOTLINE KINH DOANH</div>
            <div class="linetitle"></div>
            <!-- <div class="name-company">CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN</div> -->
            <div class="content" :gutter="24">
              <div class="title-contact">
                <strong>Kinh doanh Miền Bắc</strong>
              </div>

              <div class="phone-contact">
                <i class="fa-solid fa-phone-volume"></i>
                Mr. Huy: <a href="tel:0355031003">0355 031 003</a>
              </div>

              <div class="title-contact">
                <strong>Kinh doanh Miền Trung</strong>
              </div>

              <div class="phone-contact">
                <i class="fa-solid fa-phone-volume"></i>
                Mr. Huy: <a href="tel:0812327699">0812 327 699</a>  
              </div>
              <div class="title-contact">
                <strong>Kinh doanh Miền Nam</strong>
              </div>

              <div class="phone-contact">
                <i class="fa-solid fa-phone-volume"></i>
                Mr. Phong: <a href="tel:0833009203">0833 009 203</a> 
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24">
          <div>
            <div class="title">KẾT NỐI VỚI CHÚNG TÔI</div>
            <div class="linetitle"></div>
            <!-- <div class="name-company">CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN</div> -->
            <div style="margin-top: 12px">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61556670721303&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="100%"
                height="150px"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                loading="lazy"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30972.4512874295!2d105.76779325678986!3d20.980468314773464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3134531fdf5395b9%3A0xfba31f9e062eadfa!2zMTYgTmfDtSA0OCBQaGFuIMSQw6xuaCBHacOzdCwgTGEgS2jDqiwgSMOgIMSQw7RuZywgSMOgIE7hu5lpLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1708394600381!5m2!1sen!2s"
                width="100%"
                height="150px"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- <div class="footer-top background-gradient"></div>
      <div class="footer-body">
        <div class="footer-body-col4">
          <img src="@/assets/images/medisun.png" alt="" style="height: 150px" />
          <div class="frameicon">
            <div class="frameFace" @click="btOpenLink">
              <i class="fa-brands fa-facebook-f"></i>
            </div>
            <div class="frameFace">
              <i class="fa-solid fa-phone"></i>
            </div>
          </div>
        </div>
        <div class="footer-body-col1">
          <h4>VỀ CHÚNG TÔI</h4>
          <div>
            <ul @click="btIntroCompany">
              Giới thiệu công ty
            </ul>
            <ul @click="btRecruit">
              Địa chỉ
            </ul>
          </div>
          <div class="footer-layout-confirm" style="display: none">
            <img src="@/assets/images/confirm.png" alt="" />
          </div>
        </div>
        <div class="footer-body-col2">
          <h4>DANH MỤC</h4>
          <div>
            <ul @click="btGLevel1Title1">
              Danh sách sản phẩm
            </ul>
          
          </div>
        </div>

        <div class="footer-body-col4">
          <h4>CHÍNH SÁCH - HƯỚNG DẪN</h4>
          <div>
            <ul @click="btGeneralPolicy">
              Hướng dẫn mua hàng
            </ul>
            <ul @click="btReturnGoods">
              Chính sách đổi trả
            </ul>
        
          </div>
        </div>
        <div class="footer-body-col5">
          <h4>LIÊN HỆ</h4>

          <div style="font-size: 13px; margin-top: 12px">
            <p>Tư vấn mua hàng</p>
            <h3 style="color: #26a9e1">0355.031.003</h3>
          </div>
          <div style="font-size: 13px; margin-top: 8px">
            <p>Góp ý, khiếu nại (7h00-22h00)</p>
            <h3 style="color: #26a9e1">0355.031.003</h3>
          </div>
        </div>
      </div> -->
      <!-- <div class="footer-bottom">
        <div class="footer-bottom-line"></div>
        <div style="text-align: center">
          <div class="footer-info-company">
            <p>
              ©2024 CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN Số giấy phép:
              11/SLDTBXH-GP cấp ngày 12/03/2021 <br />
              Cơ quan cấp phép: Ủy ban nhân dân thành phố Hà Nội, Văn phòng Lao
              động và Thương binh xã hội
            </p>
            <p>Địa chỉ: Tòa Metropolitan CT36A Định Công, Hoàng Mai, Hà Nội</p>
            <p>Email: tbytmedisun@gmail.com - Số điện thoại: 0355.031.003</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import footerMobile from "./footerMobile.vue";

export default {
  data() {
    return {
      inputEmail: "",
    };
  },
  components: {
    footerMobile,
  },
  methods: {
    openProgramLoyalPoint() {
      this.$router.push({
        path: "/chuong-trinh-tich-diem",
      });
    },
    openNCC() {
      let routeData = this.$router.resolve({ path: "/dang-ky-nha-phan-phoi" });
      window.open(routeData.href, "_blank");
    },
    btDelivery() {
      this.$router.push({ path: "/huong-dan" });
    },
    btIntroCompany() {
      this.$router.push({ path: "/gioi-thieu-cong-ty" });
    },
    btRecruit() {
      this.$router.push({ path: "/dia-chi" });
    },
    btShopLst() {
      this.$router.push({ path: "/he-thong-cua-hang" });
    },

    btGeneralPolicy() {
      this.$router.push({ path: "/huong-dan" });
    },

    btReturnGoods() {
      this.$router.push({ path: "/chinh-sach" });
    },
    btGLevel1Title1() {
      this.$router.push({
        path: "/danh-sach-san-pham",
      });
    },
    btGLevel1Title2() {
      this.$router.push({
        path: "/mua-ho-hang-Nhat",
      });
    },
    btOpenLink() {
      window.open(
        "https://www.facebook.com/profile.php?id=100068855400399",
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-footer {
  background: #232323;
  color: #fff;
  padding: 36px 0;
}
.container {
  display: block;
  .title {
    font-weight: 600;
    margin-bottom: 12px;
    text-align: left;
    font-size: 20px;
    margin-top: 24px;
  }
  .linetitle {
    height: 3px;
    width: 80px;
    background: #fff;
    border-radius: 8px;
    // margin: auto;
  }
  .name-company {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    margin-top: 24px;
  }
  .content {
    padding: 16px 0;
    margin-top: 24px;
    .top-content {
      display: flex;
      i {
        background: #26a9e1;
        padding: 4px;
        border-radius: 80px;
        color: #fff;
        font-size: 10px;
      }
      .title-content {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-left: 8px;
        text-transform: uppercase;
      }
    }
    .sub-content {
      font-size: 14px;
      margin: 16px 24px;
      text-align: justify;
    }
    .title-contact {
      font-size: 14px;
      strong {
        font-size: 15px;
      }
    }
    .phone-contact {
      font-size: 14px;
      margin: 16px 24px;
      i {
        color: red;
      }
    }
    .shopCell-frame-info {
      .shopCell-des {
        text-align: left;
        display: flex;
        margin: 16px auto;
        p {
          margin-left: 8px;
          margin-top: 6px;
        }
        .shopCell-des-icon {
          height: 30px;
          width: 30px;
          border: 1px dashed rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          position: relative;
          i {
            color: rgba(0, 0, 0, 0.54);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
.footer-top {
  margin-top: 16px;
  height: 5px;
  display: flex;
  justify-content: space-between;
}
.footer-top-title {
  color: #fff;
  padding-top: 16px;
  padding-left: 100px;
}
.footer-frame-input {
  padding-right: 100px;
}
.footer-input-email {
  padding-top: 5px;
  width: 400px;
}
ul {
  padding: 2px 0px;
  color: #26a9e1;
  font-size: 14px;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #26a9e1;
}
.footer-body {
  padding: 16px;
  padding-top: 30px;
  display: grid;
  grid-template-columns: 21% 18% 18% 23% 20%;
  position: relative;
  padding-bottom: 80px;
}

ul:hover {
  color: #4f79db;
}

.footer-body-col4 {
  margin-bottom: 16px;
  .frameicon {
    display: flex;
    .frameFace {
      width: 20px;
      // height: 32px;
      background: #fff;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      padding: 8px;
      margin-right: 8px;
      i {
        font-size: 16px;
        background: #fff;

        color: #26a9e1;
      }
    }
  }
}

.footer-layout-confirm {
  margin-top: 45px;
  margin-left: -20px;
  margin-bottom: 20px;
}

.footer-bottom-line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.54);
}
.footer-info-company {
  margin-top: 15px;
  font-size: 14px;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.7);
  p {
    line-height: 24px;
  }
}
.flex-space {
  display: flex;
  justify-content: space-between;
}

.frame-footer-mobile {
  height: 75px;
}
@media screen and (max-width: 678px) {
  .footer-body {
    grid-template-columns: 50% 50%;
  }
}
</style>
