<template>
  <div class="container">
    <div class="0 frame-cell">
      <div class="shopCell-frame-map">
        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30972.4512874295!2d105.76779325678986!3d20.980468314773464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3134531fdf5395b9%3A0xfba31f9e062eadfa!2zMTYgTmfDtSA0OCBQaGFuIMSQw6xuaCBHacOzdCwgTGEgS2jDqiwgSMOgIMSQw7RuZywgSMOgIE7hu5lpLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1708394600381!5m2!1sen!2s"
            width="100%"
            height="360px"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="shopCell-frame-info">
        <div class="shopCell-frame-name bg-gradient-light">
          <i class="fa-solid fa-store"></i>
          <div class="shopCell-line"></div>
          <div class="shopCell-line"></div>
          <p class="shopCell-title">CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN</p>
          <div class="shopCell-triangle"></div>
        </div>
        <div class="shopCell-des">
          <div class="shopCell-des-icon">
            <i class="fa-solid fa-location-dot"></i>
          </div>
          <p class="">
            Tòa Metropolitan CT36A Định Công, Hoàng Mai, Hà Nội
          </p>
        </div>
        <div class="shopCell-des">
          <div class="shopCell-des-icon">
            <i class="fa-solid fa-s"></i>
          </div>
          <p class="">Shopee: Medisun Group</p>
        </div>
        <div class="shopCell-des">
          <div class="shopCell-des-icon">
            <i class="fa-brands fa-facebook-f"></i>
          </div>
          <p class="">Facebook: Thiết bị y tế Medisun Group</p>
        </div>
        <div class="shopCell-des">
          <div class="shopCell-des-icon">
            <i class="fa-solid fa-phone"></i>
          </div>
          <p class="">Số điện thoại: 0355.031.003</p>
        </div>
        <div class="shopCell-des">
          <div class="shopCell-des-icon">
            <i class="fa-solid fa-envelope"></i>
          </div>
          <p class="">Email: tbytmedisun@gmail.com</p>
        </div>
        <div class="shopCell-des">
          <div class="shopCell-des-icon">
            <i class="fa-solid fa-clock"></i>
          </div>
          <p class="">Thời gian mở cửa: 7h00 - 22h00 hàng ngày</p>
        </div>
      </div>
    </div>
    <div class="shopCell-line-bottom"></div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.container {
  display: block;
  max-width: 100%;
}
.frame-cell {
  display: flex;
  margin-top: 40px;
  margin-left: 20px;
}
.shopCell-frame-map {
  width: 40%;
  // height: 270px;
  #map {
    iframe {
      border-radius: 23px;
    }
  }
}
.shopCell-map {
  width: 100%;
  height: 100%;
}
.shopCell-frame-info {
  width: 50%;
  padding-left: 40px;
}
.shopCell-frame-name {
  height: 40px;
  width: 100%;
  text-align: left;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  position: relative;
}
.shopCell-frame-name i {
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 3px;
}
.shopCell-line {
  margin-left: 6px;
  width: 3px;
  background-color: #fff;
  height: 48px;
}
.shopCell-title {
  margin-top: 12px;
  margin-left: 16px;
  color: #fff;
  font-size: 16px;
}
.shopCell-triangle {
  position: absolute;
  right: 0px;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 24px solid rgb(255, 255, 255);
}
/*  */
.shopCell-des {
  text-align: left;
  display: flex;
  margin: 16px auto;
}
.shopCell-des p {
  margin-left: 8px;
  margin-top: 6px;
}
.shopCell-des-icon {
  height: 30px;
  width: 30px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
}
.shopCell-des-icon i {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shopCell-line-bottom {
  margin: 20px;
  width: calc(100% - 80px);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 678px) {
  .frame-cell {
    display: block;
    margin-top: 20px;
    margin-left: 0;
    .shopCell-frame-map {
      width: 100%;
    }
    .shopCell-frame-info {
      width: 100%;
      padding-left: 0px;
      .shopCell-frame-name {
        height: auto;
      }
    }
  }
}
</style>
