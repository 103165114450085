import axios from "axios";
import { techsso } from "./baseUrl";

const service = axios.create({
  baseURL: techsso, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    config.data.StoreCode = "MD000007";

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    if (response.data == null) {
      response.data = {};
    }
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.

    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
