<template>
  <div id="productLst">
    <div style="min-height: 500px" v-loading="loadingFull">
      <div class="container" style="padding-top: 200px">
        <div class="tagname">
          <a class="tag" href="">Trang chủ / </a>
          <a class="tag" href="">Danh sách sản phẩm</a>
        </div>
        <div class="bound">
          <div class="left-bound">
            <div class="box">
              <div class="title-box">Hỗ trợ trực tuyến</div>
              <div class="line-box">
                <i class="fa-solid fa-mobile-screen-button"></i>
                Hotline: <a href="tel:0355031003"> 0355 031 003</a>
              </div>
              <div class="line-box">
                <i class="fa-solid fa-mobile-screen-button"></i>
                Hotline: <a href="tel:0812327699"> 0812 327 699</a>
              </div>
              <div class="line-box">
                <i class="fa-solid fa-mobile-screen-button"></i>
                Hotline: <a href="tel:0902018148"> 0902 018 148</a>
              </div>
              <div class="line-box">
                <i class="fa-regular fa-envelope"></i>
                tbytmedisun@gmail.com
              </div>
            </div>
            <div class="box">
              <div class="title-box">Tin tức mới nhất</div>
              <div
                class="line-box"
                v-for="(item, index) in userCareLst"
                :key="index"
              >
                <userCareCell :userCareInfo="item"></userCareCell>
              </div>
            </div>
          </div>
          <div class="productLst-frame" v-if="moreProductLst">
            <div class="productLst-right">
              <div class="productLst-container">
                <div
                  class="productLst-item"
                  v-for="product in moreProductLst"
                  :key="product.ProductID"
                >
                  <producCell :productInfo="product"></producCell>
                </div>
              </div>

              <div class="productLst-container" v-if="productSearch">
                <div
                  class="productLst-item"
                  v-for="product in productSearch"
                  :key="product.ProductID"
                >
                  <producCell :productInfo="product"></producCell>
                </div>
              </div>

              <div
                class="text-center"
                v-if="moreProductLst.length === 0 && productSearch.length === 0"
              >
                <p style="color: #454545; padding: 60px">
                  Rất tiếc chúng tôi chưa có sản phẩm nào thuộc danh mục này
                </p>
              </div>

              <div class="frame-button" v-if="isShowBtMore" @click="btSeeMore">
                <el-button class="bt-seemore" :loading="loading"
                  >Xem thêm</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-mobile">
        <!-- <div>Anh thanhf</div> -->
        <div class="tagname" style="font-size: 14px">
          <a class="tag" href="">Trang chủ / </a>
          <a class="tag" href="">Danh sách sản phẩm</a>
        </div>
        <div class="container-mobile-lstproduct">
          <el-row class="">
            <el-col
              :lg="6"
              :md="8"
              :sm="8"
              :xs="12"
              v-for="product in this.moreProductLst"
              :key="product.ProductID"
              class=""
            >
              <producCell :productInfo="product"></producCell>
            </el-col>
          </el-row>
          <div
            class="frame-button"
            style="margin-bottom: 30px"
            v-if="isShowBtMore"
            @click="btSeeMore"
          >
            <el-button class="bt-seemore" :loading="loading"
              >Xem thêm</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import producCell from "../body/components/productCell.vue";
import { GetProductLstPublic } from "@/api/productApi.js";
import { mapActions, mapGetters } from "vuex";
import { TGetPostLstPublic } from "@/api/postApi.js";
import userCareCell from "./components/user-care-cell.vue";

export default {
  data() {
    return {
      productLstMobile: [],
      pageNunber: 1,

      loadingFull: true,
      isShowBtMore: true,
      search: "",
      numberRow: 15,
      keyProduct: 0,
      loading: false,
      productSeeMoreLst: [],
      productLst: [],
      userCareLst: [],
    };
  },
  components: {
    producCell,
    userCareCell,
  },
  computed: {
    ...mapActions(["updateProductSearch"]),
    ...mapActions(["updateMoreProductLst"]),
    ...mapGetters(["productSearch"]),
    ...mapGetters(["moreProductLst"]),
  },
  methods: {
    getPostLstPublic() {
      TGetPostLstPublic({
        PageNumber: 1,
        RowspPage: 4,
        Search: "",
        Type: "",
      }).then((res) => {
        if (res.RespCode == 0) {
          this.userCareLst = res.Data;
        }
      });
    },
    getProductLstSearch() {
      if (this.productSearch.length === 0) {
        this.loadingFull = true;
        GetProductLstPublic({
          Search: this.search,
          RowspPage: 1000,
          PageNumber: 1,
        }).then((res) => {
          this.loadingFull = false;
          this.keyProduct = this.keyProduct + 1;
          this.productLst = res.data.ProductPostLst;
          this.$store.dispatch("updateProductSearch", this.productLst);
          this.isShowBtMore = false;
        });
      } else {
        this.productLst = this.productSearch;
      }
    },
    getProductLst() {
      this.loading = true;

      GetProductLstPublic({
        Search: this.search,
        RowspPage: this.numberRow,
        PageNumber: this.pageNunber,
      }).then((res) => {
        this.loading = false;
        this.loadingFull = false;
        // var productCheck = this.productLst
        this.productLst = this.moreProductLst.concat(
          res.Data.map((p) => {
            return {
              ...p,
              LinkImage: p.ImgLst[0] ? p.ImgLst[0].LinkImage : "",
            };
          })
        );

        this.$store.dispatch("updateMoreProductLst", this.productLst);
        if (this.moreProductLst.length < 14) {
          this.isShowBtMore = false;
        } else {
          this.isShowBtMore = true;
        }
        if (res.data.ProductPostLst.length < 14) {
          this.isShowBtMore = false;
        } else {
          this.isShowBtMore = true;
        }
        document
          .getElementById("productLst")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
    btSeeMore() {
      this.pageNunber += 1;
      this.getProductLst();
    },
    checkLoadItem() {
      this.pageNunber = 1;

      if (this.moreProductLst.length === 0) {
        this.loading = true;
        GetProductLstPublic({
          Search: "",
          RowspPage: this.numberRow,
          PageNumber: this.pageNunber,
          RowID: this.$route.params.id ? parseInt(this.$route.params.id) : null,
        }).then((res) => {
          this.productLst = [];
          this.productLst = res.Data.map((p) => {
            return {
              ...p,
              LinkImage: p.ImgLst[0] ? p.ImgLst[0].LinkImage : "",
            };
          });
          this.loading = false;
          this.$store.dispatch("updateMoreProductLst", this.productLst);
          if (this.productLst.length < 14) {
            this.isShowBtMore = false;
          } else {
            this.isShowBtMore = true;
          }
          document
            .getElementById("productLst")
            .scrollIntoView({ behavior: "smooth" });
        });
      }

      this.loadingFull = false;
    },
  },
  watch: {
    $route(to, from) {
      this.$router.go();
      this.$store.dispatch("updateMoreProductLst", []);
    },
  },
  created() {
    this.getPostLstPublic();
    console.log("window.location.href", window.location.href);
    if (window.location.href.indexOf("kiem") > -1) {
      this.getProductLstSearch();
    } else {
      this.checkLoadItem();
    }

    this.$watch(
      () => this.$route.params,
      () => {
        this.checkLoadItem();
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.bound {
  display: flex;

  .left-bound {
    width: 300px;
    margin-top: 18px;
    margin-right: 16px;

    .box {
      margin-top: 20px;

      border: 1px solid #26a9e1;
      .title-box {
        padding: 12px;
        color: #fff;
        background: #26a9e1;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
      }
      .line-box {
        padding: 12px;
        border-bottom: 1px solid #edf0f3;
        font-size: 15px;
      }
    }
  }
}
a {
  text-decoration: none;
  color: #000;
}
.tagname {
  background: #edf0f3;
  padding: 20px;
}
ul {
  padding: 13px 20px;
  margin: 0px;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.productLst-frame {
  display: flex;
  padding: 30px 0;
  width: calc(100% - 300px);
}

.productLst-frame-menu {
  background-color: #fff;
  margin-right: 20px;
  margin-top: 7px;
  width: 20%;
  height: 320px;
  padding-top: 2px;
  padding-bottom: 0px !important;
  border-radius: 8px;
}

.productLst-frame-menu ul:hover {
  color: #fff;
  background-color: #26a9e1;
}
.productLst-right {
  width: 100%;
}

.productLst-container {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.productLst-item {
  margin-top: 0px;
  height: auto;
}
.frame-button {
  text-align: center;
}
.bt-seemore {
  padding: 10px 30px;
  border-radius: 8px;
  color: #26a9e1;
  font-size: 16px;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #26a9e1;
}
.bt-seemore:hover {
  background-color: #26a9e1;
  color: #fff;
}
/* Mobile */
.container-mobile-lstproduct {
  width: 100%;
  /* height: 400px; */
  /* background: #26A9E1; */
  /* margin: auto 8px;
  padding-bottom: 30px; */
  place-items: center;
}
</style>
