<template>
  <div class="container">
    <div class="company-container">
      <header>
        <h1>TẠI SAO CHỌN CHÚNG TÔI ?</h1>
        <div class="linetitle"></div>
      </header>

      <el-row>
        <el-col :lg="8" :md="12" :sm="24">
          <div class="box-item">
            <i class="fa-solid fa-business-time"></i>
            <div class="content-item">
              <div class="title">Dịch vụ chuyên nghiệp</div>
              <div class="subtitle">
                Đáp ứng nhanh nhạy với nhu cầu của thị trường và yêu cầu của
                khách hàng
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="24">
          <div class="box-item">
            <i class="fa-solid fa-users-gear"></i>
            <div class="content-item">
              <div class="title">Đội ngũ kỹ sư giàu kinh nghiệm</div>
              <div class="subtitle">
                Đội ngũ nhân viên là các kỹ sư, cử nhân giàu kinh nghiệm, năng
                động, sáng tạo và chuyên nghiệp
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="24">
          <div class="box-item">
            <i class="fa-solid fa-file-shield"></i>
            <div class="content-item">
              <div class="title">Sản phẩm chất lượng</div>
              <div class="subtitle">
                Cung cấp trang thiết bị y tế hiện đại nhất nhằm nâng cao hiệu
                quả cho người sử dụng và an toàn cho bệnh nhân
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="24">
          <div class="box-item">
            <i class="fa-brands fa-google-scholar"></i>
            <div class="content-item">
              <div class="title">Năng lực và kinh nghiệm</div>
              <div class="subtitle">
                Có kinh nghiệm tư vấn và lắp đặt hệ thống trang thiết bị chẩn
                đoán hình ảnh cho các cơ sở y tế
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="24">
          <div class="box-item">
            <i class="fa-regular fa-calendar-days"></i>
            <div class="content-item">
              <div class="title">Đối tác đáng tin cậy</div>
              <div class="subtitle">
                Trên nền tảng tạo dựng niềm tin và hướng tới lợi ích chung,
                Medisun luôn đảm bảo cung cấp trang thiết bị chất lượng cạo tới
                các bệnh viện và cơ sở y tế trên cả nước
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="24">
          <div class="box-item">
            <i class="fa-solid fa-bullseye"></i>
            <div class="content-item">
              <div class="title">Giải pháp công nghệ hiện đại</div>
              <div class="subtitle">
                Tư vấn và cung cấp cho khách hàng các giải pháp công hệ hiện đại
                nhất
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="bg-company">
        <div class="info-company">
          <div class="title">Giới thiệu về Medisun</div>
          <div class="sub-title">
            Công ty Cổ phần Thiết Bị Y Tế Medisun với gần 5 năm hình thành và
            phát triển. Công ty chúng tôi luôn không ngừng phấn đấu trở thành
            đơn vị đi đầu trong việc setup và cung cấp những giải pháp cho phòng
            khám đa khoa trên cả nước một cách bền vững và chất lượng, với trọng
            tâm là lĩnh vực chẩn đoán hình ảnh đặc biệt là máy siêu âm tại Việt
            Nam và khu vực. Đề cao cung cấp máy móc trang thiết bị chất lượng
            đảm bảo yếu tố về thu hồi vốn nhanh, vận hành bền vững lâu dài. Hiện
            nay Công ty Cổ phần Thiết bị y tế Medisun là đại lý phân phối chính
            thức sản phẩm chẩn đoán hình ảnh cũng như máy siêu âm của hãng
            Canyearn - Việt Nam
          </div>
          <div
            class="linetitle"
            style="margin-top: 16px; background: #fff"
          ></div>
          <div class="box-intro">
            <div class="stt">1</div>
            <div class="content">
              <div class="title-intro">Gần 5 năm kinh nghiệm</div>
              <div class="subtitle">
                Trải qua nhiều năm kinh nghiệm thực tiễn với đội ngũ nhân viên
                là các kỹ sư, cử nhân giàu kinh nghiệm, năng động, sáng tạo và
                chuyên nghiệp, Medisun đã nhanh chóng trở thành một trong các
                Nhà cung cấp giải pháp và thiết bị y tế có uy tín tại Việt Nam.
              </div>
            </div>
          </div>
          <div class="box-intro">
            <div class="stt">2</div>
            <div class="content">
              <div class="title-intro">Giá trị khách hàng nhận được</div>
              <div class="subtitle">
                Đến với Medisun, khách hàng sẽ được hưởng các gói bảo hành từ 12
                tháng đến 36 tháng và không giới hạn thời gian bảo dưỡng định kỳ
                máy hàng tháng. Đội ngũ tư vấn để lựa chọn các giải pháp phù hợp
                đầu tư trang thiết bị y tế công nghệ cao. Chính sách sau bán
                hàng bảo dưỡng định kỳ dài hạn tận tình, chu đáo phục vụ 24/7.
              </div>
            </div>
          </div>
          <div class="box-intro">
            <div class="stt">3</div>
            <div class="content">
              <div class="title-intro">Hỗ trợ trả góp 0% lãi suất</div>
              <div class="subtitle">
                Medisun chúng tôi cung cấp các giải pháp thanh toán giúp Quý
                khách lựa chọn các hình thức như trả góp Không Tính Lãi Suất với
                thời gian tốt nhất. Phương thức thanh toán tiện lợi và dài hạn.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  line-height: 1.6;
}
.container {
  display: block;
}
.company-container {
  // max-width: 800px;
  margin: 0 auto;
  // padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 0;
  // margin-top: 20px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .linetitle {
    height: 6px;
    background: #31a9e1;
    width: 80px;
    margin: auto;
    border-radius: 8px;
  }
  .box-item {
    display: flex;
    padding: 24px;
    i {
      font-size: 50px;
      margin-right: 20px;
      margin-top: 20px;
      color: #31a9e1;
    }
    .content-item {
      color: #333;
      line-height: 30px;
      .title {
        font-weight: 600;
      }
      .subtitle {
        font-size: 14px;
        text-align: justify;
      }
    }
  }
  .bg-company {
    background: url("../../../assets/images/bg1.png") center no-repeat;
    background-size: cover !important;
    overflow: hidden;
    .info-company {
      background: #05455780;
      margin: 30px 0;
      padding: 30px;
      color: #fff;
      max-width: 600px;
      .title {
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 30px;
      }
      .sub-title {
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
      }
    }
    .box-intro {
      display: grid;
      grid-template-columns: 44px calc(100% - 44px);
      margin-top: 30px;
      .stt {
        font-size: 30px;
        border: 2px solid #fff;
        height: 44px;
        width: 44px !important;
        border-radius: 50%;
        text-align: center;
        font-weight: 600;
        line-height: 44px;
      }
      .content {
        padding-left: 20px;
        .title-intro {
          font-weight: 600;
          line-height: 30px;
        }
        .subtitle {
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
        }
      }
    }
  }
}

header {
  text-align: center;
  margin-bottom: 20px;
}

h1 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}
h2 {
  color: #333;
}
</style>
