
import requestShop from '@/utils/requestShop.js'
import requestOrder from '@/utils/requestOrder.js'
import requestTechsso from '@/utils/requestTechsso.js'

export function GetProductGroupLst(data) {
    return requestShop({
      url: 'WEB/GetProductGroupLst',
      method: 'post',
      data: data
    })
}
export function GetItemLst(data) {
  return requestShop({
    url: 'WEB/GetItemLst',
    method: 'post',
    data: data
  })
}
export function GetProductInfo(data) {
  return requestShop({
    url: 'WEB/GetProductInfo',
    method: 'post',
    data: data
  })
}

// Order

export function GetCity(data) {
  return requestTechsso({
    url: 'Default/GetCity',
    method: 'post',
    data: data
  })
}


export function GetDistrictByCity(data) {
  return requestTechsso({
    url: 'Default/GetDistrictByCity',
    method: 'post',
    data: data
  })
}

export function GetCommuneByCityAndDistrict(data) {
  return requestTechsso({
    url: 'Default/GetCommuneByCityAndDistrict',
    method: 'post',
    data: data
  })
}

export function CreateOrder(data) {
  return requestOrder({
    url: 'Order/CreateOrder',
    method: 'post',
    data: data
  })
}
// Medisun
export function GetProductLstPublic(data) {
  return requestTechsso({
    url: 'Product/GetProductLstPublic',
    method: 'post',
    data: data
  })
}
export function GetProductInfoPublic(data) {
  return requestTechsso({
    url: 'Product/GetProductInfoPublic',
    method: 'post',
    data: data
  })
}


