const storeConfig = {
  state: {
    productInfo: {},
    productLst: [],
    moreProductLst: [],
    productInCart: [],
    productSearch: [],
    areaUser: "Miền Bắc",
    location: null,
    productRegisterLst: [],
  },
  getters: {
    productInfo: (state) => state.productInfo,
    productLst: (state) => state.productLst,
    productInCart: (state) => state.productInCart,
    moreProductLst: (state) => state.moreProductLst,
    productSearch: (state) => state.productSearch,
    areaUser: (state) => state.areaUser,
    location: (state) => state.location,
    productRegisterLst: (state) => state.productRegisterLst,
  },
  mutations: {
    setProduct(state, productInfo) {
      state.productInfo = productInfo;
    },
    setProductLst(state, productLst) {
      state.productLst = productLst;
    },
    setProductInCart(state, productInCart) {
      state.productInCart = productInCart;
    },
    setAreaUser(state, areaUser) {
      state.areaUser = areaUser;
    },
    setLocation(state, location) {
      state.location = location;
    },
    setMoreProductLst(state, moreProductLst) {
      state.moreProductLst = moreProductLst;
    },
    setProductSearch(state, productSearch) {
      state.productSearch = productSearch;
    },
    setProductRegisterLst(state, productRegisterLst) {
      state.productRegisterLst = productRegisterLst;
    },
  },
  actions: {
    updateProduct({ commit }, productInfo) {
      commit("setProduct", productInfo);
    },
    updateProductLst({ commit }, productLst) {
      commit("setProductLst", productLst);
    },
    updateProductInCart({ commit }, productInCart) {
      commit("setProductInCart", productInCart);
    },
    updateAreaUser({ commit }, areaUser) {
      commit("setAreaUser", areaUser);
    },
    updateLocation({ commit }, location) {
      commit("setLocation", location);
    },
    updateMoreProductLst({ commit }, moreProductLst) {
      commit("setMoreProductLst", moreProductLst);
    },
    updateProductSearch({ commit }, productSearch) {
      commit("setProductSearch", productSearch);
    },
    updateProductRegisterLst({ commit }, productRegisterLst) {
      commit("setProductRegisterLst", productRegisterLst);
    },
  },
};

export { storeConfig };

export function setProductCartLst(data) {
  var dataMap = [];
  if (data) {
    dataMap = data.map((p, index) => {
      return {
        ...p,
        Key: index + 1,
      };
    });
  }
  var jsonString = JSON.stringify(dataMap);
  localStorage.setItem("ProductCartLst", jsonString);
}
export function getProductCartLst() {
  var storedJsonString = localStorage.getItem("ProductCartLst");
  var productCartLst = JSON.parse(storedJsonString);
  return productCartLst;
}
