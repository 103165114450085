<template>
  <div id="app">
    <!-- <div id="loading-frame">
      <loading></loading>
    </div> -->
    <go-top></go-top>
    <div id="app-frame">
      <div>
        <navbar
          style="margin: auto"
          v-if="!$route.meta.supplierActive"
          @emitMenuSlide="setValue($event)"
        />
        <megaMenu
          style="margin: auto"
          v-if="!$route.meta.supplierActive"
          :openMenuSlide="openMenu"
        />
      </div>

      <router-view />
      <Footer />
      <chatBox v-if="!$route.meta.hideChatBox" />
    </div>
  </div>
</template>
<script>
import navbar from "./views/header/navbar/navbar.vue";
import megaMenu from "./views/header/menu/index.vue";
import Footer from "./views/footer/footer.vue";
import chatBox from "./views/chat-box/chat.vue";
import GoTop from "@inotom/vue-go-top";
// import loading from "./views/extension/loading.vue";
export default {
  name: "App",
  data() {
    return {
      loading: true,
      openMenu: 0,
    };
  },
  components: {
    navbar,
    megaMenu,
    Footer,
    GoTop,
    // loading,
    chatBox,
  },
  computed: {
    //  isShow(){
    //   let show = true
    //    if  (window.location.href.indexOf("gio-hang-cua-ban") > -1) {
    //     show = false
    //   //  document.getElementById("loading-frame").style.display = "none";
    //   } else {
    //     show = true
    //     // document.getElementById("loading-frame").style.display = "block";
    //   }
    //   return show
    //  }
  },
  methods: {
    setValue(data) {
      console.log(" navbar");
      this.openMenu = data;
    },
  },
  // created() {
  //   setTimeout(function () {
  //     this.loading = false;
  //     document.getElementById("loading-frame").style.display = "none";
  //     document.getElementById("app-frame").style.display = "block";
  //   }, 1000);
  // },
};
</script>

<style>
@import "./style/default.css";
#loading-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(127, 216, 71, 0.1);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
#app-frame {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}
#goTop {
  width: 50px !important;
  height: 50px !important;
  right: 40px !important;
}
</style>
