import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/vi";
import Vuex from "vuex";
import { storeConfig } from "./store";
import VueQRCodeComponent from "vue-qrcode-component";

Vue.component("qr-code", VueQRCodeComponent);



Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(ElementUI, locale);

const store = new Vuex.Store(storeConfig);
// console.log("Anh thanhf", storeConfig)

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
