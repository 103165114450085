<template>
  <div class="container-usercare" v-loading="loading">
    <div class="tagname">
      <a class="tag" href="">Trang chủ / </a>
      <a class="tag" href="">Tin tức /</a>
      <a class="tag" href=""> {{ this.$route.params.name }} </a>
    </div>
    <div class="layout">
      <div class="layout-usercare">
        <h1>
          {{ userCareDetail.Title }}
        </h1>
        <div class="line"></div>
        <div style="display: flex; justify-content: space-between">
          <div></div>

          <h5 style="color: gray; font-weight: 400; margin-top: 5px">
            <i class="fa-regular fa-calendar-days"></i>
            {{ userCareDetail.TimeCreate }}
          </h5>
        </div>

        <p class="post__subtitle" v-if="userCareDetail.SubTitle">
          {{ userCareDetail.SubTitle }}
        </p>

        <div class="image-post" v-if="userCareDetail.LinkImage">
          <img :src="userCareDetail.LinkImage" alt="" />
        </div>

        <div class="post-content">
          <div v-for="(content, index) in userCareDetail.Contents" :key="index">
            <h2 class="post-content__title">
              {{ content.title }}
            </h2>
            <div v-html="content.desc"></div>
            <!-- <div>{{ content.desc }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="post-relate" v-if="userCareLst.length > 0">
      <h3>Bài viết liên quan</h3>
      <div class="lstCareCell">
        <userCareCell
          v-for="(userCare, index) in userCareLst"
          :key="index"
          :userCareInfo="userCare"
          @checkSeeMore="getDetail($event)"
        ></userCareCell>
      </div>
    </div>
  </div>
</template>

<script>
import userCareCell from "../components/user-care-cell.vue";

import { GetPostByID, TGetPostLstPublic } from "@/api/postApi.js";
export default {
  data() {
    return {
      userCareDetail: {},
      userCareLst: [],
      userCare: [],
      loading: false,
      key: 0,
    };
  },
  components: {
    userCareCell,
  },
  watch: {
    $route() {
      this.$router.go();
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.getPostByID();
      }
    );
    this.getPostByID();
    this.getPostLstPublic();
  },
  methods: {
    getPostByID() {
      GetPostByID({
        PostID: this.$route.params.id,
      }).then((res) => {
        if (res.RespCode == 0) {
          this.userCareDetail = res.Data;
          this.userCareDetail.Contents = JSON.parse(res.Data.Description);
        }
      });
    },
    getPostLstPublic() {
      TGetPostLstPublic({
        PageNumber: 1,
        RowspPage: 8,
        Search: "",
        Type: "",
      }).then((res) => {
        if (res.RespCode == 0) {
          this.userCareLst = res.Data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.layout {
  padding: 24px;
  background: #fff;
  margin-top: 16px;
  border-radius: 16px;
  .layout-usercare {
    max-width: 597px;
    margin: auto;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 40px;
    }
    .line {
      height: 1px;
      background: #ccc;
      margin-top: 8px;
    }
    .post__subtitle {
      color: #000;
      font-size: 16px;
      margin-top: 1rem;
      background: #728aa14d;
      padding: 20px;
      border-radius: 12px;
    }
    .post-content__title {
      font-size: 16px;
      line-height: 30px;
    }
    .image-post {
      width: 100%;
      margin: auto;
      margin-top: 16px;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    .post-content {
      font-size: 16px;
      margin: 16px 0;
    }
    .post__type {
      background: #e6e6e6;
      border-radius: 50px;
      padding: 2px 10px;
      color: #00573e;
      margin-top: 8px;
      font-size: 12px;
    }
  }
}
.container-usercare {
  padding-top: 190px;
  margin-top: 10px;
}
.lstCareCell {
  margin: 0px -10px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.tag {
  text-decoration: none;
  color: #26a9e1;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.tagname {
  background: #edf0f3;
  padding: 20px;
}
.post-relate {
  margin-top: 30px;
}
@media (max-width: 998px) {
  .container-usercare {
    padding: 0px 20px !important;
  }
}

@media (max-width: 998px) {
  .container-usercare {
    padding: 0px 20px !important;
  }
}
@media (max-width: 678px) {
  .container-usercare {
    padding: 0px 8px !important;
    .tagname {
      margin-left: 0;
    }
    .layout {
      padding: 10px 12px !important;
      .layout-usercare {
        h1 {
          font-size: 20px;
        }
      }
      .post__subtitle {
        padding: 12px 10px;
        font-size: 14px;
      }
    }
    .image {
      width: calc(100% - 20px) !important;
    }
  }
  .post-relate {
    .lstCareCell {
      grid-template-columns: 100%;
    }
  }
}
@media (min-width: 1240px) {
  .container-usercare {
    width: 1240px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
</style>
