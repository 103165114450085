<template>
  <div>
    <div class="menu-container">
      <div class="menu-container-top text-center">
        <div
          class="menu-frame-menu"
          v-for="(item, index) in routesMenu"
          :key="index"
        >
          <div @click="btPush(item)">
            <span class="menu-title"> {{ item.name }} </span>
          </div>
          <div
            v-if="item.name == 'Danh sách sản phẩm'"
            class="menu-menu mega-menu5"
            style="--heightMega: 0px"
          >
            <div class="menu-menu-right2">
              <div class="menu-right-option">
                <div
                  class="menu-right-option-items"
                  v-for="(item, index) in tagLst"
                  :key="index"
                >
                  <p @click="btPushType(item)">{{ item.Attribute }}</p>
                  <div class="subMenu">
                    <div v-if="item.Options.length > 0">
                      <div
                        class="subMenuItem"
                        v-for="(sub, insub) in item.Options"
                        :key="insub"
                        @click="btPushType(sub)"
                      >
                        {{ sub.Value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-menu-mobile"
      id="fullMenu"
      style="
        z-index: 500;
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      "
    >
      <div class="mobile-nav" id="menu-mobile" style="z-index: 1000">
        <ul
          style="
            margin-top: 0px;
            display: block;
            position: none;
            list-style: none;
            padding-left: 0px;
            height: 56px;
          "
        >
          <li
            class="navbar-item-mobile"
            v-for="(item, index) in routesMenu"
            :key="index"
          >
            <a
              style="width: 85%"
              v-if="item.name != 'Danh sách sản phẩm'"
              @click="btPush(item)"
            >
              <p class="navbar-title-mobile" id="title-product">
                {{ item.name }}
              </p>
            </a>
            <div v-else>
              <!-- <div style="display: flex; height: 30px">
                <a style="width: 85%">
                  <p
                    class="navbar-title-mobile"
                    id="title-TBYT"
                    @click="$router.push('/danh-sach-san-pham')"
                  >
                    {{ item.name }}
                  </p></a
                >
                <i
                  class="fa-solid fa-chevron-down"
                  style="
                    color: #fff;
                    padding: 5px;
                    margin-top: -3px;
                    margin-bottom: 8px;
                  "
                  id="ic-down-TBYT"
                  @click="btSeeMenuTBYT"
                >
                </i>
              </div>

              <div id="menuTBYTMobile">
                <ul class="menu-item-mobile menu-TBYT-mobile">
                  <li style="margin-left: 10px" class="li-mobile">
                    <div
                      class="li-mobile item-mobile"
                      v-for="(item, index) in tagLst"
                      :key="index"
                    >
                      <div
                        style="text-transform: uppercase"
                        @click="btPushType(item)"
                      >
                        {{ item.Attribute }}
                      </div>
                      <div v-if="item.Options.length > 0" style="margin: 8px">
                        <div
                          v-for="(line, id) in item.Options"
                          :key="id"
                          style="line-height: 20px"
                          @click="btPushType(line)"
                        >
                          - {{ line.Value }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                
              </div> -->
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                background-color="#272727"
                text-color="#fff"
                active-text-color="#fff"
                style="margin-top: 0px"
              >
                <el-submenu index="1">
                  <template slot="title">
                    <span>DANH SÁCH SẢN PHẨM</span>
                  </template>
                  <div v-for="(item, index) in tagLst" :key="index">
                    <el-menu-item
                      :index="'1-'+index"
                      v-if="item.Options.length == 0"
                      @click="btPushType(item)"
                      >{{ item.Attribute }}</el-menu-item
                    >

                    <el-submenu :index="'2-'+index" v-else>
                      <template slot="title">{{ item.Attribute }}</template>
                      <el-menu-item
                        :index="'3-'+id"
                        v-for="(line, id) in item.Options"
                        :key="id"
                        @click="btPushType(line)"
                        >{{ line.Value }}</el-menu-item
                      >
                    </el-submenu>
                  </div>
                </el-submenu>
              </el-menu>
            </div>
          </li>
        </ul>
      </div>
      <div class="clear-fix-layout" id="clear-fix" @click="closeNavMenu"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { constantRoutes } from "@/router";
import { DTPGetValue } from "@/api/defaultApi.js";
export default {
  data() {
    return {
      listGroup: [],
      dataAllGroup: [],
      tagLst: [],
    };
  },
  props: {
    openMenuSlide: Number,
  },

  computed: {
    ...mapActions(["updateMoreProductLst"]),
    routesMenu() {
      return constantRoutes.filter((p) => p.hidden == false);
    },
  },
  watch: {
    openMenuSlide() {
      document.documentElement.style.overflow = "hidden";
      var x = document.getElementById("menu-mobile");
      var y = document.getElementById("clear-fix");
      if (x.style.display === "none" || x.style.display === "") {
        x.style.display = "block";
        y.style.display = "block";
        document.getElementById("fullMenu").style.display = "flex";
      } else {
        x.style.display = "none";
        y.style.display = "none";
        document.getElementById("fullMenu").style.display = "none";
        document.querySelector("body").style.overflow = "hidden";
      }
    },
  },
  methods: {
    getValue() {
      DTPGetValue({
        Table: "TypeProduct",
      }).then((res) => {
        this.tagLst = res.Data;
      });
    },
    btSeeMenuTBYT() {
      var menu = document.getElementById("menuTBYTMobile");
      var icon = document.getElementById("ic-down-TBYT");

      if (menu.offsetHeight) {
        menu.style.height = 0;
        icon.style.transform = "rotate(0)";
      } else {
        var wrapper = document.querySelector(".menu-TBYT-mobile");
        menu.style.height = wrapper.offsetHeight + 10 + "px";
        icon.style.transform = "rotate(3.142rad)";
      }
    },
    btPushType(data) {
      this.$router.push("/danh-sach-san-pham/" + data.RowID);
    },
    btPush(data) {
      this.$router.push(data.path);
      this.closeNavMenu();
    },
    closeNavMenu() {
      var x = document.getElementById("menu-mobile");
      var y = document.getElementById("clear-fix");
      x.style.display = "none";
      y.style.display = "none";
      document.getElementById("fullMenu").style.display = "none";
      document.documentElement.style.overflow = "auto";
      document.querySelector("body").style.removeProperty("overflow");
    },
  },
  created() {
    this.getValue();
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  background: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  z-index: 102;
}
.menu-container-top {
  width: 1240px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  /* z-index: 10; */
}
.menu-frame-menu {
  padding: 15px 0px;
  cursor: pointer;
}
.menu-frame-menu:hover {
  color: #26a9e1;
  font-weight: 700 !important;
}
.menu-title {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.menu-title:hover {
  color: #26a9e1;
  font-weight: 500;
}
.menu-menu {
  position: absolute;
  display: none;
  background-color: rgb(255, 255, 255);
  top: 48px;
  left: -45px;
  width: 330px;
  z-index: 500;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding-bottom: 8px;
}

.menu-right-option-items:hover {
  background-color: rgba(38, 169, 225, 0.1);
  .subMenu {
    display: block;
  }
}
.menu-menu:hover + .menu-frame-menu .menu-title {
  color: #e4803a;
}
.menu-menu-left {
  margin: 0px;
  border-radius: 0 0 0 24px;
  height: var(--heightMega);
  width: 25%;
  background: linear-gradient(90deg, #f0bd4a -10.81%, #e68241 118.47%);
}
.menu-menu-right {
  width: 100%;
}
.menu-menu-right:hover {
  color: #fff;
}
.menu-frame-menu:nth-child(2) .menu-menu {
  position: absolute;
  left: 30%;
}
.menu-frame-menu:nth-child(3) .menu-menu {
  position: absolute;
  left: 31%;
}
.menu-frame-menu:nth-child(4) .menu-menu {
  position: absolute;
  left: 48.5%;
}
.menu-frame-menu:nth-child(5) .menu-menu {
  position: absolute;
  left: 58.5%;
}
.btn-view-all {
  padding: 6px 32px 20px 13px;
  text-align: start;
}
.btn-view-all2 {
  padding: 6px 32px 20px 45px;
  text-align: start;
}

.btn-view-all2 > a {
  font-size: 16px;
  font-weight: 400;
  color: #26a9e1;
  text-decoration: none;
}
.btn-view-all2 > i {
  color: #26a9e1;
  font-size: 20px;
  margin-left: 4px;
  opacity: 1;
}
.btn-view-all > a {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #26a9e1;
  text-decoration: none;
}
.btn-view-all > i {
  color: #26a9e1;
  font-size: 20px;
  margin-left: 4px;
  opacity: 1;
}
.items-menu-right:hover {
  background-color: #26a9e1;
  color: #f0bd4a;
}
.menu-menu-right2 {
  margin-top: 0px;
  width: 100%;
  overflow-y: hidden;
  height: auto;
  /* place-items: center; */
}
.menu-right-option-items {
  height: 36px;
  // position: absolute;
  // color: var(--menu-color, #000);
  z-index: 1000;
  .subMenu {
    display: none;
    position: absolute;
    margin-top: -40px;
    right: -230px;
    width: 230px;
    z-index: 1000000;
    background: #fff;
    box-shadow: 0 0px 2px rgb(0, 0, 0, 0.25);

    border-radius: 0 8px 8px 0;
    .subMenuItem {
      height: 36px;
      color: #26a9e1;
      line-height: 36px;
      font-weight: 400;
      text-align: left;
      padding: 0 16px;
      &:hover {
        color: #26a9e1;
        background: rgba(38, 169, 225, 0.1);
      }
    }
  }
}
.menu-right-option {
  font-size: 16px;
}
.menu-right-option p {
  height: 20px;
  color: #26a9e1;
  text-align: start;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-transform: capitalize;
}
// .menu-right-option p:hover {
//   color: #fff;
// }
.group-title {
  text-align: start !important;
  font-size: 16px;
  font-weight: 400;
  color: #26a9e1;
  padding: 10px 32px 7px 45px;
}
.menu-menu:hover .menu-title > span {
  color: red !important ;
}
.group-title:hover {
  color: white;
}
.menu-box-logo {
  margin: auto 8px;
}
.box-logo {
  margin: 8px;
  border-radius: 8px;
  margin-top: 16px;
  background: #fff;
  border: 1px solid #fff;
  height: 100px;
  place-items: center;
  position: relative;
}
.logo {
  width: 70%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.menu-frame-menu:hover .mega-menu1 {
  display: block;
}
.menu-frame-menu:hover .mega-menu2 {
  display: block;
}
.menu-frame-menu:hover .mega-menu3 {
  display: block;
}
.menu-frame-menu:hover .mega-menu4 {
  display: block;
}
.menu-frame-menu:hover .mega-menu5 {
  display: block;
}
.line-holizontal {
  height: 24px;
  width: 5px;
  margin-right: 8px;
  border-radius: 2px;
  background: #26a9e1;
  display: none;
}
@media (min-width: 0) and (max-width: 1200px) {
  .menu-container {
    display: none !important;
    text-transform: uppercase;
  }
}

.mobile-nav {
  position: fixed;
  right: 0;
  top: 0;
  display: none;
  padding-top: 18px;
  bottom: 0;
  z-index: 500;
  background: #272727;
  /* background: rgba(0, 0, 0, 0.8); */
  /* left: -260px; */
  min-width: 240px;
  max-width: 300px;
  overflow-y: auto;
  transition: 0.4s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.navbar-item-mobile {
  min-height: 55px;
  margin: 0px;
  line-height: 15px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #1e1e1e;
  // padding-top: 10px;
}
.navbar-title-mobile {
  color: #fff;
  margin-top: 3px;
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 14px;
  line-height: 56px;
}

.navbar-title-mobile:hover {
  cursor: pointer;
  background: none;
}

.menu-item-mobile {
  margin-top: 5px;
  width: 100%;
  list-style: none;
  padding-left: 0px;
}
#menuTPCNMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}
#menuDMPMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}
#menuTMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}
#menuCSCNMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}
#menuCDMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}
#menuTBYTMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
  margin-top: -8px;
}

.li-mobile {
  width: 100%;
  /* margin-top: 4px; */
  margin-bottom: 4px;
  margin-left: 7px;
  cursor: pointer;
}
.item-mobile {
  /* margin: 6px;
  margin-left: 5px; */
  color: #fff;
  font-size: 14px;
  padding: 4px 4px;
  font-weight: 400;
}
.clear-fix-layout {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  /* width: 600px; */
  z-index: 8;
  width: calc(100% - 240px);
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.fa-chevron-down {
  cursor: pointer;
}
</style>

<style>
.el-loading-mask {
  z-index: 1000 !important;
}
</style>
