<template>
  <div class="frame-cel" @click="openUserCareDetail">
    <div class="cell-col-image">
      <img class="cell-frame-image" :src="userCareInfo.LinkImage" alt="" />
    </div>
    <div style="margin-bottom: 8px; min-height: 120px">
      <h4 class="title">
        {{ userCareInfo.Title }}
      </h4>
      <!-- <span class="type">{{ userCareInfo.Type }}</span> -->

      <h5 class="subTitle font-size-h6">
        {{ userCareInfo.SubTitle }}
      </h5>
      <h6 style="font-weight: 400; margin: 6px 0px; color: gray">
        <i class="far fa-clock"></i>
        {{ userCareInfo.TimeCreate }}
      </h6>
    </div>
  </div>
</template>

<script>
// import { baseUrlImg } from "@/utils/baseUrl.js";
export default {
  props: {
    userCareInfo: Object,
    linkImage: String,
  },
  methods: {
    openUserCareDetail() {
      const convertedTitle = this.convertToVietnameseNoSign(
        this.userCareInfo.Title
      );

      const finalURL = `/tin-tuc-bai-viet/${convertedTitle}/${this.userCareInfo.PostID}`;
      this.$router.push({
        path: finalURL,
      });
    },

    convertToVietnameseNoSign(str) {
      const diacriticsMap = {
        à: "a",
        á: "a",
        ạ: "a",
        ả: "a",
        ã: "a",
        â: "a",
        ầ: "a",
        ấ: "a",
        ậ: "a",
        ẩ: "a",
        ẫ: "a",
        ă: "a",
        ằ: "a",
        ắ: "a",
        ặ: "a",
        ẳ: "a",
        ẵ: "a",
        è: "e",
        é: "e",
        ẹ: "e",
        ẻ: "e",
        ẽ: "e",
        ê: "e",
        ề: "e",
        ế: "e",
        ệ: "e",
        ể: "e",
        ễ: "e",
        ì: "i",
        í: "i",
        ị: "i",
        ỉ: "i",
        ĩ: "i",
        ò: "o",
        ó: "o",
        ọ: "o",
        ỏ: "o",
        õ: "o",
        ô: "o",
        ồ: "o",
        ố: "o",
        ộ: "o",
        ổ: "o",
        ỗ: "o",
        ơ: "o",
        ờ: "o",
        ớ: "o",
        ợ: "o",
        ở: "o",
        ỡ: "o",
        ù: "u",
        ú: "u",
        ụ: "u",
        ủ: "u",
        ũ: "u",
        ư: "u",
        ừ: "u",
        ứ: "u",
        ự: "u",
        ử: "u",
        ữ: "u",
        ỳ: "y",
        ý: "y",
        ỵ: "y",
        ỷ: "y",
        ỹ: "y",
        đ: "d",
      };

      return str
        .toLowerCase()
        .replace(
          /[àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\d]/g,
          (match) => diacriticsMap[match] || ""
        )
        .replace(/[^\w]+/g, "-") // Thay thế các ký tự không phải chữ cái và số bằng dấu gạch ngang
        .replace(/^-+|-+$/g, ""); // Loại bỏ dấu gạch ngang ở đầu và cuối chuỗi
    },
    seeMore() {
      if (this.userCareInfo != null) {
        this.$emit("checkSeeMore", this.userCareInfo);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.frame-cel {
  background-color: #fff;
  cursor: pointer;

  width: calc(100% - 20px);
  // width: 25% !important;
  margin: auto;
  margin-bottom: 12px;
  min-height: 278px;

  .title {
    font-weight: 500;
    margin: 8px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .type {
    background: #fff;

    border: 1px solid #4a4f63;
    color: #4a4f63;
    height: 24px;
    border-radius: 18px;
    font-size: 12px;
    padding: 3px 8px;
    line-height: 24px;
    margin: 0 8px;
    width: auto;
    margin-right: 8px;
  }
  .subTitle {
    font-weight: 400;
    // margin: 8px 10px;
    // margin-top: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 13px;
  }
}
.cell-col-image {
  margin: 0px;
  height: auto;
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 4px;
  }
}

@media (max-width: 900px) {
  .cell-frame-image {
    height: 150px !important;
  }
}
@media (max-width: 700px) {
  .cell-frame-image {
    height: 300px !important;
  }
}

/* @media (max-width: 1200px) {
  .cell-frame-image {
    height: 250px;
  }
} */
</style>
