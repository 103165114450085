<template>
  <div class="footer-mobile">
    <div class="footer-frame-icon">
      <ul>
        <li
          class="list"
          :class="{ active: isActive1 }"
          @click="activeLink1()"
        >
          <a>
            <span class="icon"><i class="fa-solid fa-house"></i></span>
            <span class="text">Trang chủ</span>
          </a>
        </li>
        <li
          class="list"
          :class="{ active: isActive2 }"
          @click="activeLink2()"
        >
          <a>
            <span class="icon"><i class="fa-solid fa-store"></i></span>
            <span class="text">Sản phẩm</span>
          </a>
        </li>
        <!-- <li
          class="list"
          :class="{ active: isActive3 }"
          @click="activeLink3()"
        >
          <a>
            <span class="icon"
              ><i class="fa-solid fa-magnifying-glass"></i
            ></span>
            <span class="text">Tìm kiếm</span>
          </a>
        </li>
        <li
          class="list"
          :class="{ active: isActive4 }"
          @click="activeLink4()"
        >
          <a>
            <span class="icon"><i class="fa-solid fa-message"></i></span>
            <span class="text">Nhắn tin</span>
          </a>
        </li>
        <li
          class="list"
          :class="{ active: isActive5 }"
          @click="activeLink5()"
        >
          <a>
            <span class="icon"><i class="fa-solid fa-user"></i></span>
            <span class="text">Tài khoản</span>
          </a>
        </li> -->
        <div class="indicator background-gradient"></div>
      </ul>
    </div>
  </div>
</template>

<script>
// const list = document.querySelectorAll(".list");
export default {
  data() {
    return {
      isActive1: true,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
    };
  },
  methods: {
    
    activeLink1() {
      this.isActive1 = true
      this.isActive2 = false
      this.isActive3 = false
      this.isActive4 = false
      this.isActive5 = false
      this.$router.push({path:'/'})
    },
    activeLink2() {
      this.isActive1 = false
      this.isActive2 = true
      this.isActive3 = false
      this.isActive4 = false
      this.isActive5 = false
      this.$router.push({path:'/danh-sach'})
    },
    activeLink3() {
      this.isActive1 = false
      this.isActive2 = false
      this.isActive3 = true
      this.isActive4 = false
      this.isActive5 = false
    },
    activeLink4() {
      this.isActive1 = false
      this.isActive2 = false
      this.isActive3 = false
      this.isActive4 = true
      this.isActive5 = false
    },
    activeLink5() {
      this.isActive1 = false
      this.isActive2 = false
      this.isActive3 = false
      this.isActive4 = false
      this.isActive5 = true
    },
  },
  created() {
    // list.forEach((item) => {
    //   item.addEventListener("click", this.activeLink());
    // });
  },
};
</script>

<style  scoped>
.footer-mobile {
  height: 55px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* right: 0;
  left: 0; */
  margin: 0;
  padding: 0;
  /* width: 100%; */
  z-index: 9999;
}
.footer-frame-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-frame-icon ul {
  display: flex;
  /* justify-content: space-between; */
  padding: 0;
  margin: 0;
}
.footer-frame-icon ul li {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
  width: 62px;
  height: 70px;
}
.footer-frame-icon ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.footer-frame-icon ul li a .icon {
  position: relative;
  display: block;
  line-height: 80px;
  font-size: 1.2em;
  text-align: center;
  transition: 0.5s;
  color: black;
}
.footer-frame-icon ul li.active a .icon {
  transform: translateY(-28px);
  color: #fff;
}
.footer-frame-icon ul li a .text {
  position: absolute;
  color: black;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(10px);
}
.footer-frame-icon ul li.active a .text {
  opacity: 1;
}
.indicator {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -50%;
  border-radius: 50%;
  border: 6px solid #e8f1ea;
  z-index: -1;
  transition: 0.5s;
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.109); */
  /* border-top: 6px solid transparent; */
}
/* .indicator::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  background: transparent;
  width: 20px;
  height: 20px;
  left: -22px;
  border-top-right-radius: 20px;
  box-shadow: 0 -10px 0 0 #e8f1ea;
}
.indicator::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  background: transparent;
  width: 20px;
  height: 20px;
  right: -22px;
  border-top-left-radius: 20px;
  box-shadow: 0 -10px 0 0 #e8f1ea;
} */
.footer-frame-icon ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(62px * 0));
}
.footer-frame-icon ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(62px * 1));
}
.footer-frame-icon ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(62px * 2));
}
.footer-frame-icon ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(62px * 3));
}
.footer-frame-icon ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(62px * 4));
}
</style>

