<template>
  <div>
    <div class="container">
      <div class="shop-container">
        <h1>CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN</h1>
        <div class="flex-between mt-25 ml-80 mr-80">
          <div class="shop-container-icon">
            <div class="shop-frame-icon">
              <i class="fa-solid fa-shield"></i>
            </div>
            <div class="flex-center mt-10">
              <h4>Giữ xe</h4>
              <h4 class="color-orange ml-5">Miễn phí</h4>
            </div>
          </div>
          <div class="shop-line"></div>
          <div>
            <div class="shop-frame-icon"><i class="fa-solid fa-wifi"></i></div>
            <div class="flex-center mt-10">
              <h4>Wifi</h4>
              <h4 class="color-orange ml-5">Miễn phí</h4>
            </div>
          </div>
          <div class="shop-line"></div>
          <div>
            <div class="shop-frame-icon">
              <i class="fa-solid fa-box"></i>
            </div>
            <div class="flex-center mt-10">
              <h4>Sản phẩm</h4>
              <h4 class="color-orange ml-5">Chính hãng</h4>
            </div>
          </div>
          <div class="shop-line"></div>
          <div>
            <div class="shop-frame-icon">
              <i class="fa-solid fa-headphones"></i>
            </div>
            <div class="flex-center mt-10">
              <h4>Tư vấn</h4>
              <h4 class="color-orange ml-5">Nhiệt tình</h4>
            </div>
          </div>
          <div class="shop-line"></div>
          <div>
            <div class="shop-frame-icon">
              <i class="fa-solid fa-dollar-sign"></i>
            </div>
            <div class="flex-center mt-10">
              <h4>Thanh toán</h4>
              <h4 class="color-orange ml-5">Đa dạng</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="shop-container">
        <shopCell />
      </div>
    </div>
    <div>
      <div class="container-mobile">
        <div class="shop-mobile-container">
          <h1 class="font-size-h2">CÔNG TY CỔ PHẦN THIẾT BỊ Y TẾ MEDISUN</h1>
          <div class="flex-between mt-10">
            <div class="shop-mobile-container-icon">
              <div class="shop-mobile-frame-icon">
                <i class="fa-solid fa-shield"></i>
              </div>
              <div class="mt-10">
                <h4>Giữ xe</h4>
                <h4 class="color-orange">Miễn phí</h4>
              </div>
            </div>

            <div class="shop-mobile-container-icon">
              <div class="shop-mobile-frame-icon">
                <i class="fa-solid fa-wifi"></i>
              </div>
              <div class="mt-10">
                <h4>Wifi</h4>
                <h4 class="color-orange">Miễn phí</h4>
              </div>
            </div>

            <div class="shop-mobile-container-icon">
              <div class="shop-mobile-frame-icon">
                <i class="fa-solid fa-box"></i>
              </div>
              <div class="mt-10">
                <h4>Sản phẩm</h4>
                <h4 class="color-orange">Chính hãng</h4>
              </div>
            </div>

            <div class="shop-mobile-container-icon">
              <div class="shop-mobile-frame-icon">
                <i class="fa-solid fa-headphones"></i>
              </div>
              <div class="mt-10">
                <h4>Tư vấn</h4>
                <h4 class="color-orange">Nhiệt tình</h4>
              </div>
            </div>

            <div class="shop-mobile-container-icon">
              <div class="shop-mobile-frame-icon">
                <i class="fa-solid fa-dollar-sign"></i>
              </div>
              <div class="mt-10">
                <h4>Thanh toán</h4>
                <h4 class="color-orange">Đa dạng</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="shop-container">
          <shopCell />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shopCell from "../body/components/shopCell.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      inputAddress: "",
      shopLst: [],
      allShopUpharma: [],
      citys: [],
      districts: [],
      wards: [],

      areaUser1: "",
      city: "",
      district: "",
      ward: "",
      gettingLocation: false,
      // location: {},
    };
  },
  components: {
    shopCell,
  },
  computed: {
    ...mapGetters(["areaUser", "location"]),
    ...mapActions(["updateAreaUser", "updateLocation"]),
  },

  created() {},
};
</script>

<style scoped>
h1 {
  text-align: center;
  color: #26A9E1;
}
.shop-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}
.shop-frame-icon {
  height: 60px;
  width: 60px;
  background: linear-gradient(95.23deg, #26A9E1 3.69%, #fcb03a 200.33%);
  text-align: center;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
}

.shop-frame-icon i {
  font-size: 24px;
  color: #fff;
  margin-top: 18px;
  /* align-content: center; */
}
.shop-line {
  width: 1px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.25);
}

/* Danh sách cửa hàng */
.shop-input-with-select {
  width: 50%;
  padding-left: auto;
  padding-right: auto;
}
.shop-input-with-select {
  background: linear-gradient(95.23deg, #1a9b90 3.69%, #239551 100.33%);
  color: #fff;
  width: 120px;
  border-radius: 4px;
}

/* dien thoai */
.shop-mobile-container {
  background-color: #fff;
  border-radius: 0px;
  padding: 16px 8px;
  margin: 8px 0px;
  text-align: center;
}
.shop-mobile-frame-icon {
  height: 44px;
  width: 44px;
  background-color: #26A9E1;
  text-align: center;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
}
.shop-mobile-frame-icon i {
  font-size: 18px;
  color: #fff;
  margin-top: 13px;
  /* align-content: center; */
}
.shop-mobile-container-icon h4 {
  font-size: 10px;
}
@media screen and (max-width: 678px) {
  .shop-container {
    padding: 8px;
  }
}
</style>
