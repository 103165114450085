<template>
  <div class="container">
    <div class="tagname mt-20">
      <a class="tag" href="">Trang chủ / </a>
      <a class="tag" href="">Hướng dẫn</a>
    </div>
    <div class="gen-container">
      <h1>CHÍNH SÁCH ĐỔI TRẢ CỦA MEDISUN</h1>
      <div>
        <div
          class="gen-frame-name mt-30"
          style="width: fit-content; padding-right: 40px"
        >
          <i class="fa-solid fa-shield-halved"></i>
          <div class="gen-line"></div>
          <div class="gen-line"></div>
          <p class="gen-title">1. Các trường hợp được đổi/ trả hàng</p>
          <div class="gen-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20 mobiledetail">
            1.1 - Hàng giao bị lỗi kĩ thuật: <br />
            <br />
            Sản phẩm chỉ được xem là lỗi kĩ thuật khi được xác nhận từ chính
            Trung tâm kĩ thuật hoặc Trung tâm bảo hành của sản phẩm. <br />
            <br />
            Khi Quý khách gặp trục trặc với sản phẩm đặt mua tại TSS mua hộ hàng
            Nhật, vui lòng thực hiện các bước sau đây: <br />
            <br />
            Bước 1: Tự kiểm tra cách thức sử dụng sản phẩm, các thao tác được
            hướng dẫn trong “Sách hướng dẫn sử dụng” đi kèm với mỗi sản phẩm
            (nếu có). <br />
            <br />
            Bước 2: Quý khách liên hệ với trung tâm kĩ thuật/ bảo hành: HOTLINE
            038 6671009. <br />
            <br />
            Bước 3: Trong vòng 07 ngày kể từ ngày nhận hàng, nếu Quý khách được
            xác nhận từ Trung tâm kĩ thuật hoặc Trung tâm bảo hành của sản phẩm
            rằng sản phẩm bị lỗi kỹ thuật, quý khách vui lòng truy cập ngay
            (Hướng dẫn đổi trả hàng) để bắt đầu quy trình đổi trả hàng.
            <br />
            <br />1.2 - Hàng giao bị bể vỡ, sai nội dung hoặc bị thiếu: TSS mua
            hộ hàng Nhật khuyến cáo Quý khách hàng phải kiểm tra tình trạng bên
            ngoài của thùng hàng và sản phẩm trước khi thanh toán để đảm bảo
            rằng hàng hóa được giao đúng chủng loại, số lượng, màu sắc theo đơn
            đặt hàng và tình trạng bên ngoài không bị tác động (bể vỡ/ trầy
            xước). Nếu gặp trường hợp này, Quý khách vui lòng từ chối nhận hàng
            và/hoặc báo ngay cho bộ phận hỗ trợ khách hàng HOTLINE: 0383578114
            để chúng tôi có phương án xử lí kịp thời. <br />
            <br />Trong trường hợp khách hàng đã thanh toán, nhận hàng và sau đó
            phát hiện hàng hóa bị bể vỡ, sai nội dung hoặc thiếu hàng, xin vui
            lòng chụp ảnh sản phẩm gửi cho chúng tôi. Để được hỗ trợ xử lí nhanh
            chóng hơn thì quý khách nên quay video khi bóc hàng. Sau đó, chúng
            tôi hỗ trợ các bước tiếp theo như đổi/trả hàng hoặc gửi sản phẩm còn
            thiếu đến Quý khách. <br />
            <br />Sau 48h kể từ khi Quý khách nhận hàng, TSS có quyền từ chối hỗ
            trợ cho những khiếu nại theo nội dung như trên.
          </p>
        </div>
      </div>
      <div>
        <div
          class="gen-frame-name mt-30"
          style="width: fit-content; padding-right: 40px"
        >
          <i class="fa-solid fa-shield-halved"></i>
          <div class="gen-line"></div>
          <div class="gen-line"></div>
          <p class="gen-title">2. Danh mục miễn đổi/trả</p>
          <div class="gen-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20 mobiledetail">
            - Sản phẩm khuyến mãi (có giá giảm từ 10% trở lên so với giá
            gốc).<br /><br />
            - Sản phẩm đổi trả không do lỗi kỹ thuật.
          </p>
        </div>
      </div>
      <div>
        <div
          class="gen-frame-name mt-30"
          style="width: fit-content; padding-right: 40px"
        >
          <i class="fa-solid fa-shield-halved"></i>
          <div class="gen-line"></div>
          <div class="gen-line"></div>
          <p class="gen-title">3. Điều kiện đổi hàng</p>
          <div class="gen-triangle"></div>
        </div>
        <div>
          <p class="mt-20 ml-40 mr-20 mobiledetail">
            Quý khách vui lòng đọc kỹ các quy định được nêu rõ trong Chính sách
            đổi trả hàng của chúng tôi để đảm bảo rằng sản phẩm/ hàng hóa yêu
            cầu đổi/ trả thỏa mãn tất cả các điều kiện sau đây:<br /><br />
            - Sản phẩm thực hiện đổi trả phải được Quý khách đặt mua online hoặc
            mua tại hệ thống cửa hàng.<br /><br />
            - Còn nguyên đóng gói và bao bì không bị móp rách.<br /><br />
            - Tem/ phiếu bảo hành, tem thương hiệu, hướng dẫn kỹ thuật và các
            quà tặng kèm theo (nếu có) v.v… phải còn đầy đủ và nguyên vẹn.<br /><br />
            - Không có dữ liệu trong sản phẩm có thiết bị lưu trữ.<br /><br />
            - Không bị dơ bẩn, trầy xước, bể vỡ, hư hỏng, có mùi lạ hoặc có dấu
            hiệu đã qua giặt tẩy hoặc qua sử dụng.<br /><br />
            - Hàng chỉ được chấp nhận đổi để lấy một sản phẩm tương tự (cùng
            mẫu), một sản phẩm khác (cùng nhãn hàng) có giá trị tương đương hoặc
            giá trị cao hơn bù chênh lệch.<br /><br />
            - Yêu cầu Quý khách phải có hóa đơn mua hàng khi đổi hàng. Công ty
            không chấp nhận việc đổi hàng nếu không kèm chứng từ trên.<br /><br />
            - Các bộ phận, chi tiết, phụ kiện, tài liệu hướng dẫn sử dụng, quà
            tặng kèm theo (nếu có), v.v… phải còn đầy đủ và chưa có dấu hiệu sử
            dụng .<br /><br />
            Riêng đối với hàng quần áo, thời trang nếu không ưng hoặc mặc không
            vừa chúng tôi sẽ hỗ trợ pass lại cho khách khác . Tuy nhiên, việc
            thanh toán tiền sẽ được thực hiện khi dã có khách khác mua lại. Phí
            pass là 10-20% tùy mặt hàng .
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #26A9E1;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.tagname {
  margin-left: 8px;
}
.container {
  display: block;
  .gen-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    padding: 40px;
    margin-top: 20px;
    h1 {
      text-align: center;
      color: #26A9E1;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
    }
    .gen-frame-name {
      background: linear-gradient(90deg, #26A9E1 -10.81%, #e68241 118.47%);
      height: 40px;
      width: 50%;
      text-align: left;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      position: relative;
      i {
        font-size: 20px;
        color: #fff;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 4px;
      }
    }

    .gen-line {
      margin-left: 6px;
      width: 3px;
      background-color: #fff;
      height: 48px;
    }
    .gen-title {
      margin-top: 7px;
      margin-left: 16px;
      color: #fff;
      font-size: 20px;
    }
    .gen-triangle {
      position: absolute;
      right: 0px;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-right: 24px solid rgb(255, 255, 255);
    }
  }
}
@media screen and (max-width: 678px) {
  .container {
    .gen-container {
      margin: 8px;
      padding: 20px 12px;
      h1 {
        font-size: 20px;
      }
      i {
        display: none;
      }
      .gen-line {
        display: none;
      }
      .gen-frame-name {
        height: 36px;
        .gen-title {
          font-size: 16px;
          margin-left: 8px;
        }
      }
      .mobiledetail {
        margin-left: 8px !important;
        font-size: 14px;
      }
    }
  }
}
</style>
