<template>
  <div>
    <!-- <megamenu/> -->
    <banner />
    <infoCompany />
    <productType />
    <productHome />
    <userCare />
  </div>
</template>

<script>
// import megamenu from '../views/header/menu/index.vue'
import banner from "../views/header/banner/banner.vue";
import userCare from "../views/body/usercare/user-care.vue";
import productHome from "../views/body/productHome.vue";
import infoCompany from "../views/body/company/intro_company.vue";
import productType from "../views/body/productType/index.vue";
export default {
  components: {
    // megamenu,
    banner,
    userCare,
    productHome,
    infoCompany,
    productType,
  },
};
</script>

<style></style>
