import axios from 'axios'
import { baseUrlImg } from './baseUrl'


const service = axios.create({
    baseURL: baseUrlImg, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000, // request timeout
    // headers:{
    //   Accept: "application/json",
    //   "Content-Type": "application/json"
    // }
  })

export default service
