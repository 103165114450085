<template>
  <div>
    <!-- <div class="container" style="margin-top: 40px">
      <img
        src="@/assets/images/bannerbodyhome.png"
        alt=""
        style="width: 100%"
      />
    </div> -->
    <div class="container">
      <div class="product-frame">
        <div class="product-title">
          <h2 class="title">Sản phẩm</h2>
          <div class="linetitle"></div>
        </div>

        <div v-loading="loading" style="min-height: 500px">
          <div class="product-frame-cellLst pt-15">
            <div
              v-for="(product, index) in productLst"
              :key="index"
              class="product-cell"
            >
              <productCell :productInfo="product"></productCell>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-mobile">
      <div class="product-frame">
        <div class="product-title">
          <!-- <i class="fa-solid fa-capsules"></i> -->
          <h2 class="title">Sản phẩm</h2>
          <div class="linetitle"></div>
        </div>
        <!-- <button class="product-mobile-bt-seeall" @click="btSeeAll">
            Xem tất cả
            <i style="font-size: 10px" class="fa-solid fa-chevron-right"></i>
          </button> -->

        <div v-loading="loading" style="min-height: 500px">
          <el-row class="product-mobile-frame-cellLst pt-5">
            <el-col
              :lg="6"
              :md="8"
              :sm="8"
              :xs="12"
              v-for="(product, index) in productLst"
              :key="index"
              class="product-mobile-cell"
            >
              <productCell :productInfo="product"></productCell>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productCell from "./components/productCell.vue";
import { GetProductLstPublic } from "@/api/productApi.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      productLstApi: [],
      loading: false,
    };
  },
  components: {
    productCell,
  },
  methods: {
    GetListProduct() {
      if (this.productLst.length === 0) {
        this.loading = true;
        GetProductLstPublic({
          RowspPage: 15,
          PageNumber: 1,
          StoreCode: "MD000007",
          Search: "",
        }).then((res) => {
          this.loading = false;
          this.productLstApi = res.Data;
          this.$store.dispatch("updateProductLst", this.productLstApi);
        });
      }
    },
    btSeeAll() {
      this.$router.push({ path: "/danh-sach" });
    },
  },
  computed: {
    ...mapActions(["updateProductLst"]),
    ...mapGetters(["productLst"]),
  },
  created() {
    this.GetListProduct();
  },
};
</script>

<style lang="scss" scoped>
.product-frame {
  margin-top: 16px;
  background-color: #ffffff;
  border-radius: 16px;
}

.product-header {
  height: 44px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.product-title {
  display: flex;
  padding-top: 12px;
  padding-left: 20px;
}

.product-bt-seeall {
  background: none;
  border: none;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
}

.product-frame-cellLst {
  margin: auto 30px;
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  place-items: center;
  height: 100%;
}
.product-cell {
  width: 100%;
  height: 100%;
  /* height: 300px; */
}
/* Mobile */
.product-mobile-frame-cellLst {
  margin: auto 8px;
  place-items: center;
}

.product-mobile-header {
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.product-mobile-bt-seeall {
  font-size: 12px;
  background: none;
  border: none;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
}

.product-title {
  display: block;
  padding-top: 12px;
  // padding-left: 20px;
  /* color: #fff; */
  padding-left: 0px;
  text-align: center;
  h2 {
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .linetitle {
    height: 6px;
    width: 80px;
    background: #26a9e1;
    border-radius: 8px;
    margin: auto;
  }
}
</style>
