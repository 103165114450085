import request from "@/utils/requestTechsso.js";

export function TGetPostLstPublic(data) {
  return request({
    url: "TPost/TGetPostLstPublic",
    method: "post",
    data: data,
  });
}
export function GetPostByID(data) {
  return request({
    url: "TPost/GetPostByID",
    method: "post",
    data: data,
  });
}
