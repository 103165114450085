<template>
  <div class="product-type">
    <div class="title">SẢN PHẨM CHÍNH</div>
    <div class="linetitle"></div>
    <div class="subtitle">
      Dưới đây là các loại trang thiết bị mà Medisun cung cấp
    </div>
    <el-row :gutter="12">
      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="desktop">
        <div class="item-type" @click="$router.push('/danh-sach-san-pham/36')">
          <img src="@/assets/images/type/producttype1.png" alt="" />
        </div>
      </el-col>
      <el-col :lg="8" :md="8" :sm="12" :xs="24" class="mobile">
        <div class="item-type" @click="$router.push('/danh-sach-san-pham/36')">
          <img src="@/assets/images/type/producttype1-1.png" alt="" />
        </div>
      </el-col>
      <el-col :lg="8" :md="8" :sm="12" :xs="24">
        <div class="item-type" @click="$router.push('/danh-sach-san-pham/55')">
          <img src="@/assets/images/type/producttype2.png" alt="" />
        </div>
      </el-col>
      <el-col :lg="8" :md="8" :sm="12" :xs="24">
        <div class="item-type" @click="$router.push('/danh-sach-san-pham/52')">
          <img src="@/assets/images/type/producttype3.png" alt="" />
        </div>
      </el-col>
      <el-col :lg="8" :md="8" :sm="12" :xs="24">
        <div class="item-type" @click="$router.push('/danh-sach-san-pham/56')">
          <img src="@/assets/images/type/producttype4.png" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagLst: [
        "Máy siêu âm",
        "XQ+CT+MRI",
        "Nội soi tai mũi họng",
        "Nội soi tiêu hóa",
        "Xét nghiệm sinh hóa",
        "Xét nghiệm huyết học",
        "Xét nghiệm nước tiểu",
        "Xét nghiệm miễn dịch",
        "Điện giải",
        "Điện tim",
        "Điện não đồ",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.product-type {
  margin: 30px auto;
  color: #333;
  max-width: 1240px;
  .title {
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
    font-size: 20px;
  }
  .linetitle {
    height: 6px;
    width: 80px;
    background: #26a9e1;
    border-radius: 8px;
    margin: auto;
  }
  .subtitle {
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
    margin-bottom: 16px;
  }
  .item-type {
    margin-top: 10px;
    &:hover {
      box-shadow: 0 0 10px #c1c1c1;
    }
    img {
      width: 100%;
    }
  }
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1240px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
</style>
