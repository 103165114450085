<template>
  <div class="container">
    <div class="genui-container" style="line-height: 25px">
      <h1>CHÍNH SÁCH GIAO HÀNG</h1>
      <h3 class="mt-30 color-main">A. Giao hàng</h3>
      <div>
        <h3 class="mt-10">1. Medisun có giao hàng thuốc không?</h3>
        <div>
          <p class="mt-5 ml-30 mr-30">
            – Thuốc kê đơn: Medisun chỉ bán thuốc kê đơn tại   Medisun
            khi có đơn thuốc hợp lệ. Thuốc kê đơn không được bán trực tuyến.
            <br />
            – Thuốc không kê đơn: Quý khách có thể đặt thuốc không kê đơn trực
            tuyến qua trang web Medisun.vn hoặc tổng đài đặt hàng miễn cước
            0866.681.899. <br />
            Thuốc không kê đơn chỉ bán trực tuyến theo đơn vị hộp/lọ/chai chứ
            không bán lẻ theo đơn vị viên/vỉ/gói… nhằm đảm bảo chất lượng thuốc
            trong khi quá trình vận chuyển. Để mua lẻ viên/vỉ/gói…, quý khách
            vui lòng mua trực tiếp tại   Medisun
          </p>
        </div>
      </div>
      <div>
        <h3 class="mt-10">2. Khi nào tôi có thể nhận được đơn hàng?</h3>
        <div>
          <p class="mt-5 ml-30 mr-30">
            Medisun cam kết giao hàng với thời gian hiển thị trong đơn hàng, sau
            khi Quý Khách chọn đơn vị vận chuyển được gợi ý cho đơn hàng, tại
            thời điểm đặt hàng. Quý Khách có thể kiểm tra trạng thái đơn hàng
            thông qua mã đơn hàng được cung cấp hoặc liên hệ hotline
            0866.681.899 để biết được thời gian nhận được đơn hàng dự kiến.
          </p>
        </div>
      </div>
      <div>
        <h3 class="mt-10">3. Phí vận chuyển được tính như thế nào?</h3>

        <div>
          <table style="margin-top: 20px; padding: 15px">
            <tr>
              <th style="width: 33%">Miễn phí vận chuyển</th>
              <th style="width: 33%">Hỗ trợ chi phí vận chuyển</th>
              <th style="width: 33%">Trường hợp còn lại</th>
            </tr>
            <tr>
              <td>
                <div style="margin-top: -45px">
                  <p>
                    Medisun miễn phí vận chuyển với các đơn hàng thỏa mãn cả hai
                    điều kiện: <br />
                    - Đơn hàng có giá trị từ
                    <span style="font-weight: 600">300.000 VNĐ</span> (Ba trăm
                    nghìn VNĐ) trở lên. <br />
                    - Phạm vi giao hàng
                    <span style="font-weight: 600"
                      >không vượt quá 5 km tính từ   lên đơn giao tới
                      địa chỉ khách hàng.</span
                    >
                  </p>
                </div>
              </td>
              <td>
                <div>
                  <p>
                    Medisun
                    <span style="font-weight: 600">hỗ trợ 30.000 VNĐ </span>(Ba
                    mươi nghìn VNĐ)/ đơn hàng với các trường hợp: <br />
                    - Đơn hàng có giá trị từ
                    <span style="font-weight: 600">300.000 VNĐ</span> (Ba trăm
                    nghìn VNĐ) trở lên. <br />
                    - Phạm vi giao hàng
                    <span style="font-weight: 600">trên 5 km </span> tính từ nhà
                    thuốc phụ trách lên đơn hàng.
                    <span style="font-weight: 600"
                      >Phần chênh lệch chi phí vận chuyển sau khi trừ chi phí hỗ
                      trợ sẽ do khách hàng chi trả.</span
                    >
                  </p>
                </div>
              </td>
              <td>
                <div style="margin-top: -45px">
                  <p>
                    Với các đơn hàng không thuộc hai trường hợp trên, chi phí
                    vận chuyển sẽ phụ thuộc vào: <br />
                    - Đơn vị giao hàng. <br />
                    - Khoảng cách địa lý từ địa chỉ nhận hàng và địa chỉ lấy
                    hàng (  Medisun). <br />
                    - Khối lượng và kích thước đóng gói của sản phẩm
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <h3 class="mt-15">4. Medisun có giao hàng quốc tế không?</h3>

        <div>
          <p class="mt-5 ml-30 mr-30">
            Hiện tại Medisun chỉ giao hàng trong lãnh thổ Việt Nam và chưa hỗ
            trợ giao hàng quốc tế.
          </p>
        </div>
      </div>

      <div>
        <h3 class="mt-30 color-main">B. Thông tin giao hàng</h3>
        <div>
          <h3 class="mt-10">
            1.Tôi có được thông báo trước khi giao hàng không?
          </h3>
          <div>
            <p class="mt-5 ml-30 mr-30">
              Đối tác vận chuyển của Medisun sẽ liên hệ với quý khách trước khi
              giao hàng.
            </p>
          </div>
        </div>
        <div>
          <h3 class="mt-10">
            2.Tôi có thể hẹn thời gian giao hàng được không?
          </h3>
          <div>
            <p class="mt-5 ml-30 mr-30">
              Xin vui lòng liên hệ bộ phận chăm sóc khách hàng của Medisun để
              được hỗ trợ tốt nhất.
            </p>
          </div>
        </div>
        <div>
          <h3 class="mt-10">
            3. Tôi muốn kiểm tra hàng trước khi thanh toán được không?
          </h3>
          <div>
            <p class="mt-5 ml-30 mr-30">
              - Trước khi thanh toán cho đơn hàng, quý khách có thể yêu cầu nhân
              viên giao nhận mở kiện hàng để kiểm tra tình trạng ngoại quan của
              sản phẩm (không bao gồm việc dùng thử sản phẩm). <br />
              - Trong trường hợp quý khách không hài lòng với bất kì sản phẩm
              trong đơn hàng, ngay tại thời điểm được giao hàng, quý khách vui
              lòng từ chối không nhận toàn bộ kiện hàng hoặc thanh toán toàn bộ
              giá trị đơn hàng và hoàn trả lại cho bưu tá giao hàng. <br />

              Nếu có bất kỳ thắc mắc nào, vui lòng liên hệ bộ phận chăm sóc
              khách hàng của Medisun để được hỗ trợ nhanh chóng.
              
             <span style="font-weight: 500"> Medisun sẽ không chịu trách nhiệm giải quyết khiếu nại về việc
              thiếu hàng hoặc giao sai hàng của Khách hàng sau khi Khách hàng đã
              ký nhận và thanh toán.</span>
            </p>
          </div>
        </div>
        <div>
          <h3 class="mt-10">
            4. Nếu giao hàng không thành công, Medisun có thông báo cho tôi biết
            không?
          </h3>
          <div>
            <p class="mt-5 ml-30 mr-30">
              Trong trường hợp đơn hàng chưa được giao thành công đến quý khách
              lần thứ nhất, Medisun sẽ liên hệ với quý khách để sắp xếp lại lịch
              giao hàng. <br />
              Nếu Medisun không thể kết nối được với quý khách hoặc đơn vị vận
              chuyển không thể giao hàng thành công đến quý khách trong lần giao
              hàng thứ hai thì đơn hàng sẽ được hủy bởi hệ thống.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  margin-top: 10px;
  text-align: center;
  color: #e35a14;
}
p {
  font-size: 16px;
  line-height: 1.4;
}
.genui-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 40px;
  margin-top: 20px;
}
table {
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 10px;
  border: 1.5px solid rgba(0, 0, 0, 0.554);
}
</style>
